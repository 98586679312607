import { Avatar, Box, Divider, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { CurveBackgroundColor } from '../../../components/CurveBackgroundColor'
import { BsCheckCircleFill } from "react-icons/bs"

export const CommentCard = ({ name, specialty, comment }) => {
  return (
     <CurveBackgroundColor bg="#fff">
          <Flex my="20px" justifyContent={"space-between"} alignItems="center">
            <Flex>
              <Avatar src="" name={name} alt={`Picture of ${name}`} />
              <Box ml="10px">
                <Text>{name}</Text>
                <Text lineHeight={1}>{specialty}</Text>
              </Box>
            </Flex>
            <Box>
              <BsCheckCircleFill color="#707070"  size={26} />
            </Box>
          </Flex>
          <Divider />
          <Box my="20px">
            <Text>{comment}</Text>
          </Box>
        </CurveBackgroundColor>
  )
}
