import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";

import dayjs from "dayjs"
import { ReInviteModal } from "./ReInviteModal";

export const StaffTableRow = ({
  allTests,
  setRefresh,
  invitedStaff,
  enterpriseCode,
  currentClickIndex 
}) => {
  return (
    <Box>
      {invitedStaff?.map((staff, i) => (
        <Flex
          key={i}
          width="100%"
          // justifyContent={"space-between"}
          alignItems={"center"}
          bg="#fff"
          color="#565758"
          p="20px"
          borderRadius={"10px"}
          mb="10px"
        >
          <Tooltip
            label={`${staff?.staffId?.firstName} ${staff?.staffId?.lastName}`}
          >
            <Text width="20%" isTruncated textAlign="left">
              {staff?.staffId?.firstName} {staff?.staffId?.lastName}
            </Text>
          </Tooltip>
          <Tooltip label={staff?.testId?.testName}>
            <Text width="20%" isTruncated textAlign="left">
              {staff?.testId?.testName}
            </Text>
          </Tooltip>
          <Tooltip label={dayjs(staff?.createdAt).format("DD-MMM-YYYY")}>
            <Text width="20%" isTruncated textAlign="left" fontSize={"14px"}>
              {dayjs(staff?.createdAt).format("DD-MMM-YYYY")}
            </Text>
          </Tooltip>
          <Tooltip label={staff?.status}>
            <Text
              width="20%"
              isTruncated
              textAlign="left"
              color={
                staff?.status?.toLowerCase() === "pending"
                  ? "#F79256"
                  : "#1E7200"
              }
            >
              <Text
                as="span"
                color={
                  staff?.status?.toLowerCase() === "pending"
                    ? "#F79256"
                    : "#1E7200"
                }
                bg={
                  staff?.status?.toLowerCase() === "pending"
                    ? "#FBD1A24F"
                    : "#B3FED14F"
                }
                p="8px 15px"
                borderRadius={"7px"}
                textTransform="capitalize"
              >
                {staff?.status}
              </Text>
            </Text>
          </Tooltip>

          <Box width="20%" isTruncated textAlign="left" px="20px">
            <ReInviteModal
              staff={staff}
              allTests={allTests}
              setRefresh={setRefresh}
              testToTake={staff?.testId?.testName}
              enterpriseCode={enterpriseCode}
              currentClickIndex={currentClickIndex}
            />
          </Box>
        </Flex>
      ))}
    </Box>
  );
};
