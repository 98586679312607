import http, { AUTH_ROUTES } from "../../../apiConfig/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const uploadProfileImage = async (
  id,
  payload,
  setLoading,
  setRefresh
) => {
  try {
    await http.post(AUTH_ROUTES.UPDATE_IMAGE(id), payload);
    successNotifier("Image successfully updated");
    setLoading(false);
    setRefresh({});
  } catch (error) {
    setLoading(false);
    errorNotifier(error?.response?.data?.data);
    // throw new Error();
  }
};
