import { CurveBackgroundColor } from "../../../components/CurveBackgroundColor";
import { Avatar, Box, Stack, Text, Button, Spinner } from "@chakra-ui/react";
import { AiFillPlusCircle } from "react-icons/ai";
import { FiUser, FiMail } from "react-icons/fi";
import { BsTelephoneForward } from "react-icons/bs";
// import { uploadProfileImage } from '../services/updateUser'
import { useRef } from "react";
import { uploadProfileImage } from "../services/updateImage";
import { useState } from "react";

export const logUserOut = () => {
  localStorage.removeItem("HBET_TK");
  localStorage.removeItem("HBET_TKID");
  window.location.href = "/";
};

export const UserProfileSummary = ({ data, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const { email, phoneNumber, enterpriseName, lastName, image, _id } =
    data || {};
  const imageUploadRef = useRef("");

  const uploadImage = async (res) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", res);
    await uploadProfileImage(_id, formData, setLoading, setRefresh);
  };

  return (
    <Box>
      <CurveBackgroundColor bg="#fff">
        <Stack direction={"row"} alignItems="center" spacing="60px">
          <Box position={"relative"}>
            <Avatar
              size="2xl"
              name={`${enterpriseName} ${lastName}`}
              src={image}
            />
            {loading && (
              <Box position={"absolute"} right="30%" top="35%">
                <Spinner
                  thickness="4px"
                  speed="0.85s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Box>
            )}
            <AiFillPlusCircle
              fontSize={"2em"}
              cursor="pointer"
              onClick={() => imageUploadRef.current.click()}
              style={{
                color: "#09B5A9",
                position: "absolute",
                right: "-10px",
                top: "90px",
              }}
            />

            <input
              style={{ display: "none" }}
              ref={imageUploadRef}
              onChange={(res) => uploadImage(res?.target?.files?.[0])}
              type="file"
              accept=".jpg, .png, .jpeg"
            />
          </Box>

          {/* <CurveBackgroundColor smallCurve bg='#fff'> */}
          <Stack direction="row" spacing="60px" alignItems={"center"}>
            <Stack spacing="10px">
              <Stack direction="row" alignItems={"center"}>
                <FiUser />{" "}
                <Text fontWeight="bold" color="#200960" fontSize="1.5em">
                  {enterpriseName}
                </Text>
              </Stack>
              <Stack direction="row" alignItems={"center"}>
                <BsTelephoneForward />{" "}
                <Text color="#686278">{phoneNumber}</Text>
              </Stack>
              <Stack direction="row" alignItems={"center"}>
                <FiMail /> <Text color="#686278">{email}</Text>
              </Stack>
            </Stack>
            <Button
              fontSize=".7em"
              bg="#5C2BA8"
              color="#fff"
              size="sm"
              width="120px"
              onClick={logUserOut}
              _hover={{ bg: "#5C2BA8" }}
            >
              Logout
            </Button>
          </Stack>
          {/* </CurveBackgroundColor> */}
        </Stack>
      </CurveBackgroundColor>
    </Box>
  );
};
