import { Box, Flex, Select, Text } from "@chakra-ui/react";
import React from "react";
import dayjs from "dayjs";

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const year = [
  Number(dayjs().subtract(1, "year").format("YYYY")), // last year
  Number(dayjs().format("YYYY")),
];

const currentYear = dayjs().format("YYYY");
const currentMonth = dayjs().format("M");

export const CreditCard = ({ setYear, setMonth, description, value }) => {
  return (
    <Box borderRadius={"10px"} bg="#fff" p="30px">
      <Flex width="100%" justifyContent={"space-between"}>
        <Select
          border={"none"}
          size="sm"
          color="#686278"
          width="49%"
          onChange={(e) => setMonth(e.target.value)}
          defaultValue={currentMonth}
        >
          {month?.map((data, i) => {
            return (
              <option value={i + 1} key={data}>
                {data}
              </option>
            );
          })}
        </Select>
        <Select
          border={"none"}
          size="sm"
          color="#686278"
          width="49%"
          onChange={(e) => setYear(e.target.value)}
          defaultValue={currentYear}
        >
          {year?.map((data, i) => {
            return (
              <option value={data} key={i}>
                {data}
              </option>
            );
          })}
        </Select>
      </Flex>

      <Box my="27px">
        <Text
          fontSize={"3em"}
          fontWeight="bold"
          color="#200960"
          lineHeight={1.1}
          fontFamily={"Gilroy-Bold"}
        >
          {value?.totalCredits || 0}
        </Text>
        <Text color="#9E8FB5" fontSize={".9em"}>
          {description}
        </Text>
      </Box>
    </Box>
  );
};
