import { Stack, Input, Box, Text, Button, FormErrorMessage } from '@chakra-ui/react'
// import { UpdateProfileHook } from './UpdateProfileHook'
import { useEffect, useState } from 'react'
import { updatePhoneNumber } from '../services/updatePhoneNumber';


export const ChangePhoneNumberForm = ({ data, setRefresh }) => {
  const [newNumber, setNewNumber] = useState("");
  const [oldNumber, setOldNumber] = useState(() => data?.phoneNumber);
  const [confirmNewNumber, setConfirmNewNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true)

  const formTitleStyle = {
    fontSize: ".9em",
  };
  const formInputStyle = {};

  const handleUpdatePhoneNumber = async () => {
    setLoading(true)
    const payload = {
      oldNumber,
      newNumber,
      confirmNewNumber,
    };
    await updatePhoneNumber(payload, setLoading, setRefresh);
  };

  //check validation
  useEffect(() => {
    const isNotValid =
      newNumber !== confirmNewNumber || !newNumber || !confirmNewNumber;
    if (isNotValid) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [newNumber, confirmNewNumber]);

  return (
    <Box bg="#fff" p="50px">
      <Stack spacing="30px" maxW="500px">
        <Stack direction="row">
          <Box>
            <Text {...formTitleStyle}>Current Phone Number</Text>
            <Input
              onChange={(e) => setOldNumber(e.target.value)}
              type="text"
              value={oldNumber}
              {...formInputStyle}
            />
          </Box>
          <Box>
            <Text {...formTitleStyle}>New Phone Number</Text>
            <Input
              autoComplete="off"
              _autofill={"off"}
              onChange={(e) => setNewNumber(e.target.value)}
              type="text"
              value={newNumber}
              {...formInputStyle}
            />
          </Box>
        </Stack>
        <Stack direction="row">
          <Box>
            <Text {...formTitleStyle}>Confirm New Phone Number</Text>
            <Input
              autoComplete={"off"}
              autoSave={"off"}
              _autofill={"off"}
              onChange={(e) => setConfirmNewNumber(e.target.value)}
              value={confirmNewNumber}
              type="text"
              {...formInputStyle}
            />
            <FormErrorMessage>Email is required.</FormErrorMessage>
          </Box>
        </Stack>

        <Button
          _hover={{ bg: "#5C2BA8" }}
          bg="#5C2BA8"
          color="#fff"
          isLoading={loading}
          isDisabled={disabled}
          onClick={handleUpdatePhoneNumber}
        >
          Save Changes
        </Button>
      </Stack>
    </Box>
  );
}
