import React from "react";
import { Flex, Stack, Text } from "@chakra-ui/react";

export const GeneralReportcard = ({ title, total, subtitle, color }) => {
  return (
    <Flex
      direction={"column"}
      justifyContent="space-between"
      p="10px"
      borderRadius={"10px"}
      width="32%"
      // height="150px"
      bg="#fff"
    >
      <Text fontWeight={"bold"}>Total {title}</Text>
      <Stack direction={"row"}>
        <Text fontSize="5em" color={color || "#47248E"} fontWeight={"bold"}>
          {total || 0}
        </Text>
        <Text
          color="#707070"
          fontWeight={"bold"}
          alignSelf={"center"}
          fontSize=".9em"
          mt="30px !important"
        >
          {subtitle}
        </Text>
      </Stack>
    </Flex>
  );
};
