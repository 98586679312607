import { Stack, Input, Box, Text, Button } from "@chakra-ui/react";
// import { UpdateProfileHook } from './UpdateProfileHook'
import { useEffect, useState } from "react";
import { updateEmail } from "../services/updateEmail";

export const ChangeEmailForm = ({ data, setRefresh }) => {
  const [oldEmail, setOldEmail] = useState(() => data?.email);
  const [newEmail, setNewEmail] = useState("");
  const [confirmNewEmail, setConfirmNewEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  // const [disabled, setDisabled] = useState(true)
  const formTitleStyle = {
    fontSize: ".9em",
  };
  const formInputStyle = {};

  const handleUpdateEmail = async () => {
    setLoading(true);
    const payload = {
      oldEmail,
      newEmail,
      confirmNewEmail,
    };
    await updateEmail(payload, setLoading, setRefresh);
  };

  //check validation
  useEffect(() => {
    const isNotValid = newEmail !== confirmNewEmail || !newEmail || !oldEmail;
    if (isNotValid) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [newEmail, confirmNewEmail, disabled, oldEmail]);

  return (
    <Box bg="#fff" p="50px">
      <Stack spacing="30px" maxW="500px">
        <Stack direction="row">
          <Box>
            <Text {...formTitleStyle}>Current Email</Text>
            <Input
              onChange={(e) => setOldEmail(e.target.value)}
              type="text"
              value={oldEmail}
              {...formInputStyle}
            />
          </Box>
          <Box>
            <Text {...formTitleStyle}>New Email</Text>
            <Input
              onChange={(e) => setNewEmail(e.target.value)}
              type="text"
              value={newEmail}
              {...formInputStyle}
            />
          </Box>
        </Stack>
        <Stack direction="row">
          <Box>
            <Text {...formTitleStyle}>Confirm New Email</Text>
            <Input
              onChange={(e) => setConfirmNewEmail(e.target.value)}
              type="text"
              value={confirmNewEmail}
              {...formInputStyle}
            />
          </Box>
        </Stack>

        <Button
          _hover={{ bg: "#5C2BA8" }}
          bg="#5C2BA8"
          color="#fff"
          isDisabled={disabled}
          onClick={handleUpdateEmail}
          isLoading={loading}
        >
          Save Changes
        </Button>
      </Stack>
    </Box>
  );
};
