import React from 'react';
import './appLoader.css';

const AppLoader = () => {
    return ( 
        <div className="wrap-loader">
             <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        
     );
}
 
export default AppLoader;