import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  HorizontalScrollContainer,
  HorizontalScrollItem,
} from "react-simple-horizontal-scroller";
import { CurveBackgroundColor } from "../../../components/CurveBackgroundColor";
import FullPageLoader from "../../../components/fullPageLoader";
import {
  getDepartmentAnalytics,
  getStaffAnalytics,
} from "../services/analytics";
import { Chartcard } from "./ChartCard";
import { DepartmentCard } from "./departmentCards/DepartmentCard";
import { DepartmentCardChart } from "./departmentCards/DepartmentCardChart";
import { GeneralReportcard } from "./GeneralReportcard";
import { HeaderRow } from "./HeaderRow";
import { SessionUsedChartcard } from "./SessionUsedChart";

export const TestReportDetailsScreen = ({ match }) => {
  const testId = match?.params?.id;
  const name = match?.params?.name;
  const isDepartment = match?.params?.isDepartment;

  const [analytics, setAnalytics] = useState([]);
  const [loading, setLoading] = useState(true);

  const [timePeriod, setTimePeriod] = useState("daily");

  useEffect(() => {
    setLoading(true);
    if (isDepartment === true) {
      getDepartmentAnalytics(testId, setLoading, setAnalytics, timePeriod);
    } else {
      getStaffAnalytics(testId, setLoading, setAnalytics, timePeriod);
    }
  }, [testId, timePeriod, isDepartment]);
  const filterOutEmptyAnalysis = () => {
    let testTaken = analytics?.data?.dailyTestTakenData;
    if (!testTaken) return [];
    if (testTaken?.length === 1 && testTaken?.[0]?.count === 0) return [];
    return testTaken;
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box padding={"100px 50px"} bg="#EFEDF3" opacity={1}>
      <CurveBackgroundColor bg="#F6F4F9" p="30px">
        <Flex direction={["column", "column", "row"]}>
          <Box w={"100%"} mb={"50px"}>
            <HeaderRow
              timePeriod={timePeriod}
              setTimePeriod={setTimePeriod}
              title={name}
              analytics={analytics}
            />
          </Box>
        </Flex>
        <Box>
          <Text
            textTransform={"capitalize"}
            fontSize="18px"
            fontWeight={"semibold"}
            mb="20px"
          >
            General Report ( {name} )
          </Text>
          <Flex justifyContent={"space-between"}>
            <GeneralReportcard
              title={"Invites"}
              subtitle={"Invites"}
              total={analytics?.data?.staffInviteAnalytics}
            />
            <GeneralReportcard
              title={"Usages"}
              subtitle={"Logins"}
              total={analytics?.data?.totalLogins}
            />
            <GeneralReportcard
              title={"Sessions Booked"}
              subtitle={"Sessions"}
              total={analytics?.data?.totalSessionsBooked}
              color={"#09B5A9"}
            />
          </Flex>

          <Chartcard title={"Test Result"} dataToDisplay={analytics?.data} />
          {isDepartment && filterOutEmptyAnalysis()?.length > 0 && (
            <>
              <Stack direction={"column"} mt="20px">
                <Heading
                  fontFamily={"Gilroy-Bold"}
                  color="#636161"
                  fontSize={"25px"}
                  fontWeight={"semibold"}
                  py="10px"
                >
                  Test Taken Today
                </Heading>
                <Stack direction={"row"} mt="20px">
                  <HorizontalScrollContainer>
                    {analytics?.data?.dailyTestTakenData?.map((data, id) => {
                      return (
                        <HorizontalScrollItem
                          id={id}
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                        >
                          <DepartmentCardChart getData={data} />
                        </HorizontalScrollItem>
                      );
                    })}
                  </HorizontalScrollContainer>
                </Stack>
              </Stack>

              <Stack direction={"column"} mt="20px">
                <Heading
                  fontFamily={"Gilroy-Bold"}
                  color="#636161"
                  fontSize={"25px"}
                  fontWeight={"semibold"}
                  py="10px"
                >
                  Frequently Taken Tests
                </Heading>
                <Stack direction={"row"} mt="20px">
                  <HorizontalScrollContainer>
                    {analytics?.data?.dailyTestTakenData?.map((data, id) => {
                      return (
                        <HorizontalScrollItem
                          id={id}
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                        >
                          <DepartmentCard getData={data} />
                        </HorizontalScrollItem>
                      );
                    })}
                  </HorizontalScrollContainer>
                </Stack>
              </Stack>
            </>
          )}

          <SessionUsedChartcard dataToDisplay={analytics?.data} />
        </Box>
      </CurveBackgroundColor>
    </Box>
  );
};
