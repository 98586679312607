import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import { EditStaffModal } from "./EditStaffModal";
import { deleteStaff } from "../services/deleteStaff";

export const StaffCard = ({
  staff,
  departments,
  enterpriseCode,
  setRefresh,
}) => {
  const [, setLoading] = useState(false);

  const handleDelete = async (id) => {
    setLoading(true);
    await deleteStaff(id, setLoading, setRefresh);
  };

  return (
    <Box>
      {staff?.map((staf) => (
        <Flex
          key={staf?._id}
          width="100%"
          // justifyContent={"space-between"}
          alignItems={"center"}
          bg="#fff"
          color="#565758"
          p="20px"
          borderRadius={"10px"}
          mb="10px"
        >
          <Tooltip label={staf?.firstName}>
            <Text width="20%" isTruncated textAlign="left">
              {staf?.firstName} {staf?.lastName}
            </Text>
          </Tooltip>
          <Tooltip label={staf?.enterpriseDetails?.[0]?.department?.name}>
            <Text width="20%" isTruncated textAlign="left">
              {staf?.enterpriseDetails?.[0]?.department?.name}
            </Text>
          </Tooltip>
          <Tooltip label={staf?.phoneNumber}>
            <Text width="20%" isTruncated textAlign="left">
              {staf?.phoneNumber}
            </Text>
          </Tooltip>
          <Tooltip label={staf?.email}>
            <Text width="20%" isTruncated textAlign="left">
              {staf?.email}
            </Text>
          </Tooltip>
          <Box
            display="flex"
            justifyContent={"space-between"}
            width="19%"
            px="20px"
            color="#fff"
          >
            <EditStaffModal
              staffDepartment={
                staf?.[0]?.enterpriseDetails?.[0]?.department?.name
              }
              id={staf?._id}
              departments={departments}
              staffEmail={staf?.email}
              firstName={staf?.firstName}
              lastName={staf?.lastName}
              staffPhoneNumber={staf?.phoneNumber}
              enterpriseCode={enterpriseCode}
              setRefresh={setRefresh}
            />
            <CautionAlertDialog
              icon={
                <Tooltip
                  label={`delete ${staf?.firstName}`}
                  aria-label="A tooltip"
                >
                  <Flex
                    justifyContent={"center"}
                    alignItems={"center"}
                    width="60px"
                    height={"25px"}
                    px="5px"
                    border="1px solid #E6E6E6"
                    bg="inherit"
                    color="#707070"
                    borderRadius="5px"
                    cursor={"pointer"}
                  >
                    <AiOutlineDelete size={16} />
                    <Text fontSize={"11px"} ml="5px" mt="3px">
                      Delete
                    </Text>
                  </Flex>
                </Tooltip>
              }
              cautionTitle={`Are you sure you want to delete ${staf?.firstName}?`}
              mt="5px"
              small={true}
              onContinue={() => handleDelete(staf?._id)}
            />
          </Box>
        </Flex>
      ))}
    </Box>
  );
};
