import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { createDepartments } from "../services/createDepartments";
import { FormField } from "./FormField";

export const AddDepartmentModal = ({ enterpriseCode, setRefresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deptName, setDeptName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreateDept = async () => {
    setLoading(true);
    const payload = {
      name: deptName,
      enterpriseCode,
    };

    await createDepartments(payload, setLoading, setRefresh);
    onClose();
    setRefresh({});
  };

  return (
    <>
      <Button
        bg="#5C2BA8"
        color="#fff"
        px="40px"
        borderRadius={"20px"}
        _hover={{ bg: "#5C2BA8" }}
        onClick={onOpen}
      >
        Add Department
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Department</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody mt="20px">
            <FormField
              type="text"
              labelTitle={"Department Name"}
              value={deptName}
              setValue={setDeptName}
            />
            {/* <Select
              placeholder="Select head of department"
              my="25px"
              bg="#F3F3F3"
              color="#5C2BA8"
              // value={setHeadOfDept}
              onChange={(e) => setHeadOfDept(e.target.value)}
            >
              {fakeStaffData?.map((dept, i) => (
                <option key={i} value={dept?.name}>
                  {dept?.name}
                </option>
              ))}
            </Select> */}
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent="center" mb="20px">
            <Button
              px="50px"
              borderRadius={"20px"}
              bg="#5C2BA8"
              color="#fff"
              _hover={{ bg: "#5C2BA8", color: "#fff" }}
              isLoading={loading}
              isDisabled={!deptName}
              onClick={handleCreateDept}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
