import React, { useState } from "react";
import ForgotPasswordField from "./components/ForgotPasswordField";
import VerificationCodeField from "./components/VerificationCodeField";
import "./style.scss";

export const ForgotPassword = ({ match }) => {
  const [email, setEmail] = useState("");
  const token = match?.params?.token;
  const [next, setNext] = useState(token ? 2 : 1);

  return (
    <div className="login">
      <div className="container">
        <div className="left"></div>
        <div className="right">
          {next === 1 ? (
            <ForgotPasswordField
              email={email}
              setEmail={setEmail}
              goNext={setNext}
            />
          ) : next === 2 ? (
            <VerificationCodeField token={token} email={email} />
          ) : (
            <p>Encountered an error</p>
          )}
        </div>
      </div>
    </div>
  );
};
