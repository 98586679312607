import { Button, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { IoReload } from "react-icons/io5";
import { resendToken } from "../services";
// import { resendToken } from "../service/loginUser";

function ResendToken({ id }) {
  const [loading, setLoading] = useState(false);

  const handleResendToken = () => {
    setLoading(true);
    resendToken({ _id: id }, setLoading);
  };

  return (
    <Stack
      alignItems={"center"}
      justifyContent="center"
      h={["100%", "100vh"]}
      spacing={"30px"}
    >
      <Stack>
        <Text textAlign={"center"}>
          Your email hasn't been verified, please check your mail or resend
          verification link
        </Text>
        <Button
          alignSelf={"center"}
          isLoading={loading}
          bg="#5C2BA8"
          _hover={{ background: "#5C2BA8" }}
          _focus={{ boxShadow: "none" }}
          color="#fff"
          size={"sm"}
          borderRadius={"65px"}
          leftIcon={<IoReload />}
          onClick={() => handleResendToken()}
        >
          Resend verification link
        </Button>
      </Stack>
    </Stack>
  );
}

export default ResendToken;
