import { Box, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";

import bx2Icon from "../../../assets/box2.svg";
import bg from "../assets/bgImage.svg";
import { InviteStaffModal } from "./InviteStaffModal";

export const HeroCard = ({
  allTests,
  allStaff,
  setRefresh,
  departments,
  enterpriseCode,
}) => {
  return (
    <Box
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
      }}
      background-color="red"
      height="250px"
      background="inherit 0% 0% no-repeat padding-box"
      border-radius="12px"
      width="100%"
      display="flex"
      justifyContent={"space-between"}
    >
      <Box>
        <Heading
          fontFamily={"Gilroy-Bold"}
          mb="10px"
          as="h1"
          color="#403058"
          size="lg"
        >
          Invite Staff/ Department
        </Heading>
        <Text color="#403058" fontSize={"18px"}>
          Invite Staff or department in your organization to take a test or see
          a Therapist
        </Text>
      </Box>

      <Box
        display={"flex"}
        flexDirection="column"
        height={"100%"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Image width="50%" src={bx2Icon} alt="" />
        <InviteStaffModal
          allTests={allTests}
          allStaff={allStaff}
          setRefresh={setRefresh}
          departments={departments}
          enterpriseCode={enterpriseCode}
        />
      </Box>
    </Box>
  );
};
