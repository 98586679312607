// import {
//   errorNotifier,
//   successNotifier,
// } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../apiConfig/api";

export const getInviteDepartmentsHistory = async (
  code,
  setLoading,
  setAllInvitedDepartments
) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_INVITED_DEPARTMENTS(code));
    setAllInvitedDepartments(result?.data?.invites?.data?.departmentInvites);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};
