import http, { AUTH_ROUTES } from "../../../apiConfig/api";

export const getDepartments = async (code, setLoading, setAllDepartments) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_DEPARTMENTS(code));
    setAllDepartments(result?.data?.deptDetails?.data?.departments);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};
