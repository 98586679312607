import http, { AUTH_ROUTES } from "../../../apiConfig/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const inviteDepartments = async (payload, setLoading, setRefresh) => {
  try {
    await http.post(AUTH_ROUTES.INVITE_DEPARTMENT, payload);
    setLoading(false);
    successNotifier("Departments successfully INVITED");
    setRefresh({});
  } catch (error) {
    setLoading(false);
    errorNotifier(error?.response?.data?.errors?.[0]?.msg);
    // throw new Error();
  }
};
