import { toast } from "react-toastify";
import http, { AUTH_ROUTES } from "../../../apiConfig/api";

export const resendToken = async (payload, setLoading) => {
  try {
    await http.post(AUTH_ROUTES.RESEND_TOKEN, payload);
    setLoading(false);
    // successNotifier("Please check your mail");
    toast.success("Please check your mail");
  } catch (e) {
    setLoading(false);
    // errorNotifier(e.response?.data?.data);
    toast.error(e.response?.data?.data);
  }
};
