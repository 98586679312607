import React, { useEffect, useState } from "react";
import { Box, Text, Flex, ChakraProvider } from "@chakra-ui/react";

import { UserCard } from "./components/Usercard";
import { CreditCard } from "./components/CreditCard";
import { CreditUsed } from "./components/CreditUsed";
import { CreditSubscription } from "./components/CreditSubscription";
import { TableBody } from "./components/TableBody";
import { tableHead } from "./components/TableHead";
import dayjs from "dayjs";
import { PaginatedTable } from "../../components/PaginatedTable";
import { CurveBackgroundColor } from "../../components/CurveBackgroundColor";
import { useSelector } from "react-redux";
import {
  getCreditPurchased,
  getCreditUsed,
  getSubscriptions,
} from "./services";

const currentYear = dayjs().format("YYYY");
const currentMonth = dayjs().format("M");
const limit = 10;

export const Subscription = () => {
  const user = useSelector(({ userData }) => userData?.currentUser);
  const { enterpriseCode, enterpriseName } = user || {};

  const [creditPurchased, setCreditPurchased] = useState([]);
  const [creditUsed, setCreditUsed] = useState([]);

  const [creditUsedYear, setCreditUsedYear] = useState(currentYear);
  const [creditUsedMonth, setCreditUsedMonth] = useState(currentMonth);

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [refresh, setRefresh] = useState({});
  const [filter, setFilter] = useState({ sort: "desc", limit });
  const [, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    getCreditPurchased({ month, year })
      .then((data) => {
        setCreditPurchased({ ...data });
      })
      .catch((e) => null);
  }, [month, year, refresh]);

  useEffect(() => {
    getCreditUsed({ creditUsedMonth, creditUsedYear })
      .then((data) => {
        setCreditUsed({ ...data });
      })
      .catch((e) => null);
  }, [creditUsedMonth, creditUsedYear, refresh]);

  const paginate = async (page) => {
    setFilter((val) => ({ ...val, skip: limit * page }));
  };

  useEffect(() => {
    getSubscriptions()
      .then((data) => {
        setMonth((val) => val);
        setSubscriptions({ ...data });
      })
      .catch((e) => {
        setSubscriptions({ subscriptions: [], totalSubscriptions: 0 });
      })
      .finally(() => {
        setLoading((val) => ({ ...val, history: false }));
      });
  }, [filter, refresh]);
  return (
    <ChakraProvider>
      <Box padding={"100px 50px"} bg="#EFEDF3" opacity={1}>
        <CurveBackgroundColor bg="#F6F4F9" p="30px">
          <Flex
            mt="25px"
            gap={{ base: "10px", md: "15px", lg: "20px" }}
            direction={{ base: "column", lg: "row" }}
          >
            <Box width={{ base: "100%", lg: "50%" }}>
              <UserCard
                enterpriseCode={enterpriseCode}
                enterpriseName={enterpriseName}
                user={[]}
              />
            </Box>
            <Flex
              direction={{ base: "column", sm: "row" }}
              gap={{ base: "10px", md: "15px", lg: "20px" }}
              width={{ base: "100%", lg: "50%" }}
              justifyContent="space-between"
            >
              <Box width={{ base: "100%", md: "50%" }}>
                <CreditCard
                  setYear={setYear}
                  setMonth={setMonth}
                  value={creditPurchased}
                  description={"Credit Purchased"}
                />
              </Box>
              <Box width={{ base: "100%", md: "50%" }}>
                <CreditUsed
                  setYear={setCreditUsedYear}
                  setMonth={setCreditUsedMonth}
                  value={creditUsed}
                  description={"Credit Used"}
                />
              </Box>
            </Flex>
            {/* <Analytics /> */}
          </Flex>
          <Box my="40px">
            <CreditSubscription
              // update={getSubscriptionHistory}
              // user={userInformation}
              refresh={() => setRefresh({})}
            />
          </Box>
          <Box>
            <Flex
              justifyContent={"space-between"}
              px={["10px", "10px", "10px"]}
              my="20px"
            >
              <Text
                color="#2A2047"
                mt="20px"
                fontSize={"1.4em"}
                fontWeight="bold"
                fontFamily={"Gilroy-Medium"}
              >
                Purchase History
              </Text>
            </Flex>

            <PaginatedTable
              columns={tableHead}
              bg="#f6f4f9"
              total={subscriptions?.totalSubscriptions}
              length={subscriptions?.totalSubscriptions}
              updateTable={paginate}
              data={<TableBody getData={subscriptions?.subscriptions} />}
            />
          </Box>
        </CurveBackgroundColor>
      </Box>
    </ChakraProvider>
  );
};
