import "./header.scss";
import Logo from "../../assets/howbodilogo.svg";
import { getComponentTitle } from "../GetComponentTitle";
import { useLocation } from "react-router-dom";
import { Box, Heading } from "@chakra-ui/react";
import NotificationDetails from "./components/notificationDetails";
import { BsBell } from "react-icons/bs";
import { useEffect, useState } from "react";
import { getAllNotifications } from "../../pages/notifications/services/getNotifications";
import { useSelector } from "react-redux";

const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [newIncomingNotification, setNewIncomingNotification] = useState(false);
  const [
    showNewIncomingNotificationDetails,
    setShowNewIncomingNotificationDetails,
  ] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const user = useSelector(({ userData }) => userData?.currentUser);
  useEffect(() => {
    getAllNotifications(user?._id).then((res) => {
      if (res?.length > 0) {
        setNewIncomingNotification(true);
        setNotificationData(res);
      }
    });
  }, [user?._id]);

  return (
    <div className="header">
      <div className="header_logo-area">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>
        <div>
          <Heading fontFamily={"Gilroy-Bold"} className="page">
            {getComponentTitle(pathname)}
          </Heading>
        </div>
      </div>

      <Box
        mr="5%"
        // cursor="pointer"
        position="relative"
        border={"1px solid #DFD2F5"}
        borderRadius="50%"
        padding="6px"
      >
        <Box
          cursor={"pointer"}
          onClick={() => {
            setNewIncomingNotification(false);
            setShowNewIncomingNotificationDetails(true);
          }}
        >
          {newIncomingNotification && (
            <Box
              bg="#09B5A9"
              width="8px"
              height={"8px"}
              borderRadius="50%"
              position={"absolute"}
              right="5px"
            />
          )}
          <BsBell size={"1.1em"} color={"#403058"} />
        </Box>

        <NotificationDetails
          getData={notificationData}
          show={showNewIncomingNotificationDetails}
          onCloseNotification={() => {
            setShowNewIncomingNotificationDetails(false);
            // setNotificationDa({});
          }}
        />
      </Box>
    </div>
  );
};

export default Header;
