import { Divider, Stack, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { FaTimes } from "react-icons/fa";

function NotificationDetails({ show, getData, onCloseNotification }) {
  var relativeTime = require("dayjs/plugin/relativeTime");
  dayjs.extend(relativeTime);

  return show ? (
    <Stack
      bg="#fff"
      borderRadius={"10px"}
      p="15px"
      position={"absolute"}
      left="-350px"
      top="50px"
      border="1px solid #efedf3"
      width="400px"
      height={"300px"}
      maxH={"300px"}
      overflow="auto"
      color="#252525"
      maxW="400px"
    >
      <FaTimes
        onClick={() => onCloseNotification()}
        style={{
          fontSize: ".8em",
          position: "absolute",
          right: "20px",
          color: "grey",
          cursor: "pointer",
        }}
      />
      {getData?.length > 0 ? (
        getData?.map((notification) => {
          return (
            <Stack key={notification?._id}>
              <Text fontSize=".9em" fontWeight="bold">
                {notification?.title}
              </Text>
              <Text fontSize=".8em">{notification?.message}</Text>
              <Text fontSize={".7em"}>
                {dayjs(notification?.createdAt)?.from(dayjs())}
              </Text>
              <Divider />
            </Stack>
          );
        })
      ) : (
        <p>No notification available</p>
      )}
    </Stack>
  ) : null;
}

export default NotificationDetails;
