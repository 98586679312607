import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Select,
  Box,
  Button,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { FormField } from "../../staffList/components/FormField";
import { inviteDepartments } from "../services/inviteDepartment";
import { inviteStaff } from "../services/inviteStaff";

export const ReInviteModal = ({
  staff,
  allTests,
  setRefresh,
  testToTake,
  departments,
  enterpriseCode,
  currentClickIndex,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const [staffId, setStaffId] = useState(() => staff?.staffId?._id);
  const [, setTestId] = useState(() => staff?.testId?._id);
  const [inviteTitle, setInviteTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInvite = async () => {
    setLoading(true);

    const payload = {
      testId: staff?.testId?._id,
      staffId: staff?.staffId?._id,
      inviteTitle,
      enterpriseCode,
    };

    const deptPayload = {
      inviteTitle,
      enterpriseCode,
      testId: departments?.testId?._id,
      departmentId: departments?.departmentId?._id,
    };

    currentClickIndex === 1
      ? await inviteStaff(payload, setLoading, setRefresh)
      : await inviteDepartments(deptPayload, setLoading, setRefresh);
    onClose();
    setInviteTitle("");
  };

  return (
    <>
      <Text cursor="pointer" onClick={onOpen}>
        Re-Invite
      </Text>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#2A2047" fontWeight={"semibold"}>
            Re-Invite {currentClickIndex === 1 ? "Staff" : "Department"}
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <Text color="#200960" my="30px">
              Invite staff or department in your organisation to take a test or
              see a therapist
            </Text>

            {currentClickIndex === 1 ? (
              <Text
                color="#5C2BA8"
                border={"1px solid #f4f4f4"}
                borderRadius="5px"
                p="8px"
              >
                {staff?.staffId?.firstName} {staff?.staffId?.lastName}
              </Text>
            ) : (
              <Text
                color="#5C2BA8"
                border={"1px solid #f4f4f4"}
                borderRadius="5px"
                p="8px"
              >
                {departments?.departmentId?.name}
              </Text>
            )}

            <Select
              placeholder={testToTake}
              my="25px"
              bg="#F3F3F3"
              color="#5C2BA8"
              onChange={(e) => setTestId(e.target.value)}
            >
              {allTests?.map((test, i) => (
                <option value={test?._id} key={test?._id}>
                  {test?.testName}
                </option>
              ))}
            </Select>

            <Box my="20px">
              <FormField
                type="text"
                labelTitle={"Invite Title"}
                value={inviteTitle}
                setValue={setInviteTitle}
              />
            </Box>
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent="center" mb="20px">
            <Button
              px="50px"
              borderRadius={"20px"}
              bg="#5C2BA8"
              color="#fff"
              _hover={{ bg: "#5C2BA8", color: "#fff" }}
              isLoading={loading}
              isDisabled={!inviteTitle}
              onClick={handleInvite}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
