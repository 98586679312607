import { Avatar, AvatarGroup, Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import { deleteDepartment } from "../services/deleteDeaprtment";
import { EditDepartmentModal } from "./EditDepartmentModal";

export const DepartmentCard = ({ enterpriseCode, departmentData, setRefresh }) => {
  const [loading, setLoading] = useState(false)

  const handleDelete = async (id) => {
    setLoading(true)
    await deleteDepartment(id, setLoading, setRefresh);
  }
  return (
    <Box>
      {departmentData?.map((department, i) => (
        <Flex
          key={i}
          width="100%"
          justifyContent={"space-between"}
          alignItems={"center"}
          bg="#fff"
          color="#565758"
          px="20px"
          py="10px"
          borderRadius={"10px"}
          mb="10px"
        >
          <Tooltip label={department?.name}>
            <Text width="33%" isTruncated textAlign="left">
              {department?.name}
            </Text>
          </Tooltip>
          <Box display={"flex"} width="33%" justifyContent={"center"}>
            {/* <Tooltip label={"Members"}> */}
            <AvatarGroup size="md" max={3}>
              {department?.staff.length > 0 &&
                department?.staff?.map((member, i) => (
                  <Avatar key={i} name={`${member?.firstName} ${member?.lastName}`} src={""} alt={`${member?.firstName} ${member?.lastName}`} />
                ))}
            </AvatarGroup>
          </Box>
          {/* <Text width="33%" isTruncated textAlign="left">
              {department?.email}
            </Text> */}
          {/* </Tooltip> */}
          <Box
            display="flex"
            justifyContent={"flex-end"}
            width="33%"
            // pr="18%"
            color="#fff"
          >
            <EditDepartmentModal
              name={department?.name}
              _id={department?._id}
              members={department?.staff}
              setRefresh={setRefresh}
              hod={department?.headOfDepartment}
              // departmentfPhoneNumber={department?.phoneNumber}
            />
            <CautionAlertDialog
              icon={
                <Tooltip
                  label={`delete ${department?.name}`}
                  aria-label="A tooltip"
                >
                  <Flex
                    justifyContent={"center"}
                    alignItems={"center"}
                    width="60px"
                    height={"25px"}
                    px="5px"
                    border="1px solid #E6E6E6"
                    bg="inherit"
                    color="#707070"
                    borderRadius="5px"
                    cursor={"pointer"}
                  >
                    <AiOutlineDelete size={16} />
                    <Text fontSize={"11px"} ml="5px" mt="3px">
                      Delete
                    </Text>
                  </Flex>
                </Tooltip>
              }
              cautionTitle={`Are you sure you want to delete ${department?.name}?`}
              mt="5px"
              small={true}
              loading={loading}
              onContinue={() => handleDelete(department?._id)}
            />
          </Box>
        </Flex>
      ))}
    </Box>
  );
};
