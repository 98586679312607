import http, { AUTH_ROUTES } from "../../../apiConfig/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const createStaff = async (payload, setLoading, setRefresh) => {
  try {
    await http.post(AUTH_ROUTES.CREATE_STAFF, payload);
    successNotifier("Staff successfully created");
    setLoading(false);
    setRefresh({});
  } catch (error) {
    setLoading(false);
    setRefresh({});
    errorNotifier(error?.response?.data?.data);
    // throw new Error();
  }
};
