import React from "react";
import "./sidebar.scss";
// import Logo from '../../assets/howbodilogo.svg';
import { NavLink } from "react-router-dom";
import { logUserOut } from "../../pages/profile/components/UserProfileSummary";

// import { IoPersonOutline } from "react-icons/io5";

const Sidebar = ({ navOpen }) => {
  // const { pathname } = useLocation();
  return (
    <div className="side-bar" id={navOpen ? "enter" : "collapse"}>
      {/* <div className="logo">
                <img src={Logo} alt="" />
            </div> */}

      <div className="nav">
        <NavLink
          activeClassName="active"
          className="list-item"
          exact
          to="/dashboard"
        >
          <i className="fas fa-home"></i>
          <span className="label">Dashboard</span>
        </NavLink>
        <NavLink
          activeClassName="active"
          className="list-item"
          exact
          to="/dashboard/subscription"
        >
          <i className="fas fa-chalkboard-teacher"></i>
          <span className="label">Subscription</span>
        </NavLink>
        <NavLink
          activeClassName="active"
          className="list-item"
          // exact
          to="/dashboard/test-report"
        >
          <i className="fas fa-list"></i>
          <span className="label">Test Report</span>
        </NavLink>
        <NavLink
          activeClassName="active"
          className="list-item"
          exact
          to="/dashboard/invite"
        >
          <i className="fas fa-users"></i>
          <span className="label">Invite Staff</span>
        </NavLink>
        <NavLink
          activeClassName="active"
          className="list-item"
          exact
          to="/dashboard/staff"
        >
          <i
            style={{ marginLeft: "3px", marginRight: "23px" }}
            className="fas fa-map-marker-alt"
          ></i>
          <span className="label">Staff List</span>
        </NavLink>
        <NavLink
          activeClassName="active"
          className="list-item"
          exact
          to="/dashboard/comments"
        >
          <i style={{ marginRight: "23px" }} className="far fa-bell"></i>
          <span className="label">Therapist's Comment</span>
        </NavLink>
        <NavLink
          activeClassName="active"
          className="list-item"
          exact
          to="/dashboard/profile"
        >
          <i
            style={{ marginRight: "23px" }}
            className="fas fa-location-arrow"
          ></i>
          <span className="label">Profile</span>
        </NavLink>
        <NavLink
          activeClassName="active"
          className="list-item"
          exact
          to="/dashboard/notifications"
        >
          <i className="fas fa-chalkboard-teacher"></i>
          <span className="label">Notifications</span>
        </NavLink>
        <div
          className="list-item"
          style={{ cursor: "pointer" }}
          onClick={logUserOut}
        >
          <i class="fas fa-sign-out-alt"></i>
          <span className="label">Log out</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
