import { Box, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CurveBackgroundColor } from "../../components/CurveBackgroundColor";
import FullPageLoader from "../../components/fullPageLoader";

import { CommentCard } from "./components/CommentCard";
import { getDoctorsComments } from "./services/getDoctorsComments";

export const DoctorsComments = () => {
  const [doctorsComments, setDoctorsComments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //TODO: THE API IS FLAWED DUE TO THE ABSENCE OF COMPANY CODE
    getDoctorsComments(setLoading, setDoctorsComments);
  }, []);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box padding={"100px 50px"} bg="#EFEDF3" opacity={1}>
      <CurveBackgroundColor bg="#F6F4F9" p="30px">
        <Heading fontFamily={"Gilroy-Bold"} color="#403058" mb="20px">
          Recent
        </Heading>
        {doctorsComments?.length > 0 ? (
          doctorsComments?.map((data, i) => (
            <Box key={i} mb="30px">
              <CommentCard
                name={data?.doctorId?.name || "Anonymous"}
                specialty={data?.doctorId?.specialty || "Not available"}
                comment={data?.note}
              />
            </Box>
          ))
        ) : (
          <p>No comment available</p>
        )}
      </CurveBackgroundColor>
    </Box>
  );
};
