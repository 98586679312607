import {
  Box,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ArcElement, Title, Tooltip, Chart } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { GoPrimitiveDot } from "react-icons/go";
Chart?.register(Tooltip, Title, ArcElement);

export const SessionUsedChartcard = ({ dataToDisplay }) => {
  const {
    dailyAudioSession,
    dailyChatSession,
    dailyVideoSession,
    totalSessionsUsed,
  } = dataToDisplay || {};
  const data = {
    datasets: [
      {
        data: [dailyChatSession, dailyVideoSession, dailyAudioSession],
        backgroundColor: ["#47248E", "#EFDE6D", "#00B3B3"],
        cutout: 100,
      },
    ],
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 75).toFixed(2);
        // const halfFontSize = (fontSize / 3).toFixed(2);
        ctx.font = fontSize + "em Gilroy-Medium" || 0;
        ctx.textBaseline = "top";
        // var text = 200,
          let textX = Math.round(
            (width - ctx.measureText(totalSessionsUsed).width) / 2
          ),
          textY = height / 2.5;
        ctx.fillText(totalSessionsUsed || 1, textX, textY);
        ctx.restore();
        ctx.font = `12px Gilroy-Medium`;
        ctx.fillText("Session used", textX, textY + 65);
        ctx.save();
      },
    },
  ];

  return (
    <Box>
      <Heading
        fontFamily={"Gilroy-Bold"}
        color="#636161"
        fontSize={"25px"}
        fontWeight={"semibold"}
        py="30px"
      >
        Sessions Used
      </Heading>
      <Stack
        direction={["column", "row"]}
        bg="#FFFFFF"
        py="30px"
        px="30px"
        rounded={"20px"}
        align="center"
      >
        <Stack>
          <Doughnut data={data} plugins={plugins} />
        </Stack>
        <Stack direction={"row"} spacing="20px" lineHeight={1.0} mt={"60px"}>
          <VStack>
            <Text fontSize={["30px", "30px", "60px", "80px"]}>
              {dailyChatSession || 0}
            </Text>
            <HStack>
              <GoPrimitiveDot color="#47248E" size="1.6rem" />
              <Text fontSize={["9px", "10px", "12px"]}>
                Sessions used for <br /> chats
              </Text>
            </HStack>
          </VStack>
          <VStack>
            <Text mx="30px" fontSize={["30px", "30px", "60px", "80px"]}>
              {dailyVideoSession || 0}
            </Text>
            <HStack>
              <GoPrimitiveDot color="#EFDE6D" size="1.6rem" />
              <Text fontSize={["9px", "10px", "12px"]}>
                Sessions used for <br /> video calls
              </Text>
            </HStack>
          </VStack>
          <VStack>
            <Text fontSize={["30px", "30px", "60px", "80px"]}>
              {dailyAudioSession || 0}
            </Text>
            <HStack>
              <GoPrimitiveDot color="#00B3B3" size="1.6rem" />
              <Text fontSize={["9px", "10px", "12px"]}>
                Sessions used for <br />
                audio calls
              </Text>
            </HStack>
          </VStack>
        </Stack>
      </Stack>
    </Box>
  );
};
