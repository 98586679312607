import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Dashboard from "./pages/dashboard/dashboard";
import Login from "./pages/login/login";
import Signup from "./pages/signup/signup";
import { useSelector } from "react-redux";
// import store from './redux/store';
import { loaduser, logout } from "./redux/actions/auth";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// import ProtectedRoute from './routing/protectedRoute/protectedRoute'
import * as routes from "./routing/route.js";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router";
import AppLoader from "./components/appLoader/appLoader";
import { ForgotPassword } from "./pages/forgotPassword";
import VerifyEmail from "./pages/verifyEmail";

const authToken = localStorage.getItem("HBET_TK");

function App() {
  const currentUser = useSelector(({ userData }) => userData.currentUser);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    //  load user if user is logged in
    if (authToken) {
      dispatch(loaduser(history));
    }

    return () => null;
  }, [dispatch, history]);

  if (!currentUser && localStorage.getItem("HBET_TK")) {
    return <AppLoader />;
  }

  if (localStorage.getItem("HBET_TK")) {
    // redirect lo login when token expires
    let token = localStorage.getItem("HBET_TK");

    const { exp } = jwt_decode(token);
    if (exp < Date.now() / 1000) {
      dispatch(logout());
    }
  }

  return (
    <div className="App">
      <ToastContainer position="top-center" />
      <Switch>
        <Route exact path={"/login"} component={Login} />
        <Route
          path={"/verify-email/:verificationId?"}
          exact
          component={VerifyEmail}
        />
        <Route exact path={routes.Login} component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route
          path="/forgot-password/reset/:token?"
          component={ForgotPassword}
        />
        <Route path={routes.DashBoard} component={Dashboard} />
      </Switch>
    </div>
  );
}

export default App;
