import {
  Box,
  Tabs,
  Tab,
  TabList,
  Flex,
  Text,
  TabPanel,
  TabPanels,
  Circle,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { getAllNotifications } from "./services/getNotifications";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NotificationList } from "./components/NotificationList";
import FullPageLoader from "../../components/fullPageLoader";

export const Notifications = () => {
  const [allNotifications, setAllNotifications] = useState([]);

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState({});
  const user = useSelector(({ userData }) => userData?.currentUser);

  const TAB_TITLES = [{ name: "Notifications", selected: "#686278" }];

  useEffect(() => {
    getAllNotifications(user?._id).then((res) => {
      setAllNotifications(res);
      setLoading(false);
    });
  }, [user?._id, refresh]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box padding={"100px 50px"} bg="#EFEDF3" opacity={1}>
      <Tabs>
        <TabList>
          {TAB_TITLES.map((tab, id) => {
            return (
              <Tab
                key={id}
                outline="none !important"
                _focus={{ boxShadow: "none" }}
                px={[2, 2, 4, 8]}
                color="#B5B5B5"
                _selected={{
                  borderBottomColor: tab.selected,
                  color: tab.selected,
                  borderBottom: `4px solid ${tab.selected}`,
                  fontWeight: "bold",
                }}
              >
                <Flex alignItems="center">
                  <Text
                    fontSize={["xs", "sm", "md"]}
                    padding={["", "", "", "0 10px"]}
                  >
                    {tab.name}
                  </Text>
                  {
                    <Circle
                      width="30px"
                      fontSize={".7em"}
                      bg={"#352848"}
                      color="#fff"
                    >
                      {allNotifications?.length}
                    </Circle>
                  }
                </Flex>
              </Tab>
            );
          })}
        </TabList>

        <TabPanels py={4}>
          <TabPanel>
            <NotificationList data={allNotifications} setRefresh={setRefresh} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
