import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CurveBackgroundColor } from "../../components/CurveBackgroundColor";
import { HeroCard } from "./components/HeroCard";
import { InviteStaffHistoryTab } from "./components/InviteStaffHistoryTab";
import { DepartmentTabTab } from "./components/DepartmentTab";
import { useSelector } from "react-redux";
import { getAllTests } from "../test/services/getAllTests";
import FullPageLoader from "../../components/fullPageLoader";
import { getStaff } from "../staffList/services/getStaff";
import { getDepartments } from "../staffList/services/getDepartment";
import { getInvitedStaff } from "./services/getInvitedStaffHistory";
import { getInviteDepartmentsHistory } from "./services/getInvitedDepartment";

export const InviteStaff = () => {
  const [allStaff, setAllStaff] = useState([]);
  const [allTests, setAllTests] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [invitedStaff, setAllInvitedStaff] = useState([]);
  const [invitedDepartment, setAllInvitedDepartment] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState({});
  const [currentClickIndex, setCurrentClickIndex] = useState(1);

  const user = useSelector(({ userData }) => userData?.currentUser);
  const code = user?.enterpriseCode;

  useEffect(() => {
    getInvitedStaff(code, setLoading, setAllInvitedStaff);
    getAllTests(setAllTests, setLoading);
    getStaff(code, setLoading, setAllStaff);
    getDepartments(code, setLoading, setDepartments);
    getInviteDepartmentsHistory(code, setLoading, setAllInvitedDepartment);
  }, [refresh, code]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box padding={"100px 50px"} bg="#EFEDF3" opacity={1}>
      <CurveBackgroundColor bg="#F6F4F9" p="30px">
        <HeroCard
          departments={departments}
          allStaff={allStaff}
          allTests={allTests}
          enterpriseCode={code}
          setRefresh={setRefresh}
        />
        <Flex my="40px" justifyContent={"space-between"}>
          <Text color="#2A2047" fontSize={"20px"} fontWeight={"semibold"}>
            Invite History
          </Text>
          <Stack
            direction="row"
            w="250px"
            borderRadius={"33px"}
            bg="#EFEDF3"
            justifyContent={"space-between"}
          >
            <Box
              borderRadius={"10px"}
              px="20px"
              py="10px"
              width="50%"
              fontSize=".9em"
              cursor={"pointer"}
              bg={currentClickIndex === 1 ? "#fff" : "#EFEDF3"}
              color={currentClickIndex === 1 ? "#696969" : "#696969"}
              onClick={() => {
                setCurrentClickIndex(1);
                // getAllStaff();
              }}
            >
              <Text>Individual</Text>
            </Box>
            <Box
              borderRadius={"10px"}
              px="20px"
              py="10px"
              width="50%"
              fontSize=".9em"
              cursor={"pointer"}
              bg={currentClickIndex === 2 ? "#fff" : "#EFEDF3"}
              color={currentClickIndex === 2 ? "#696969" : "#696969"}
              onClick={() => {
                setCurrentClickIndex(2);
                // getDepartment();
              }}
            >
              <Text>Department</Text>
            </Box>
          </Stack>
        </Flex>

        {currentClickIndex === 1 ? (
          <InviteStaffHistoryTab
            allTests={allTests}
            enterpriseCode={code}
            setRefresh={setRefresh}
            invitedStaff={invitedStaff}
            currentClickIndex={currentClickIndex}
          />
        ) : (
          <DepartmentTabTab
            allTests={allTests}
            enterpriseCode={code}
            setRefresh={setRefresh}
            invitedDepartment={invitedDepartment}
            currentClickIndex={currentClickIndex}
          />
        )}
      </CurveBackgroundColor>
    </Box>
  );
};
