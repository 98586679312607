import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react'
import { DepartmentCard } from './Department';

export const DepartmentTabTab = ({ enterpriseCode, departments, currentClickIndex, setRefresh  }) => {
  return (
    <Box>
      <Flex
        width={"100%"}
        justifyContent="space-between"
        pr="30px"
        pl="15px"
        mt="40px"
        mb="20px"
        color="#575757"
        fontWeight={"semibold"}
      >
        <Text width="33%" textAlign={"left"}>
          Department
        </Text>
        <Text width="33%" textAlign={"center"}>
          Members
        </Text>
        <Text width="33%" textAlign={"right"}>
          Action
        </Text>
      </Flex>

      {departments?.length > 0 ? (
        <DepartmentCard
          currentClickIndex={currentClickIndex}
          departmentData={departments}
          enterpriseCode={enterpriseCode}
          // searchResult={searchResult}
          setRefresh={setRefresh}
        />
      ) : (
        <Text textAlign={"center"} color="grey" marginTop="150px">
          No data available
        </Text>
      )}
    </Box>
  );
};
