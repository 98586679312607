import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

export const DepartmentCard = ({ getData }) => {
  const { count, list } = getData || {};

  return (
    <Box>
      <Stack
        my="20px"
        w="300px"
        maxW={"300px"}
        direction={"column"}
        bg="#FFFFFF"
        p="20px"
        // px="30px"
        rounded={"20px"}
        alignItems="flex-start"
      >
        <Text fontWeight={"bold"}> {list}</Text>
        <Stack direction={"column"} alignItems="center" w="100%" spacing={"-3"}>
          <Heading fontSize={"5em"} fontFamily={"Gilroy-Bold"}>
            {count}
          </Heading>
          <Text color="#707070" fontSize={".7em"} fontWeight="bold">
            Total Staff
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
