// import { errorNotifier } from '../../../components/NotificationHandler'
import http, { AUTH_ROUTES } from "../../../apiConfig/api";

export const getTopTest = async () => {
  const resp = await http.get(AUTH_ROUTES.GET_TOP_TEST);
  let topTestArray = resp.data?.freqentlyTakenTest?.data;
  let sumUp = topTestArray
    ?.map?.((data) => data?.count)
    .reduce?.((partialSum, a) => partialSum + a, 0);
  let getPercentage = (topTestArray?.[0]?.count / sumUp) * 100;
  return {
    testName: topTestArray?.[0]?._id || "None currently",
    value: sumUp ? `${getPercentage?.toFixed(0)}%` : "0%",
  };
};
