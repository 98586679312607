import { Stack, Input, Box, Text, Button } from '@chakra-ui/react'
// import { UpdateProfileHook } from './UpdateProfileHook'
import { useState } from 'react'
import { updateEnterpriseName } from '../services/updateEnterpriseName';
// import { UserProfileContext } from '../../../context/UserContext'

export const PersonalInfoForm = ({ data, setRefresh }) => {
  // const userProfile = {
  //   firstName: data?.enterpriseName?.split(" ")[0],
  //   lastName: data?.enterpriseName?.split(" ")[1],
  //   gender: data?.gender,
  //   DOB: data?.dob,
  // };

  const [enterpriseName, setEnterpriseName] = useState(
    () => data?.enterpriseName
  );
  const [loading, setLoading] = useState(false);
  // const [lastName, setLastName] = useState(()=>userProfile?.lastName)
  // const [dob, setDob] = useState(()=>userProfile?.DOB)
  // const [gender, setGender] = useState(()=>userProfile?.gender)

  const formTitleStyle = {
    fontSize: ".9em",
  };
  const formInputStyle = {};


  const handleUpdateEnterpriseName = async () => {

    setLoading(true)
    const payload = {
      enterpriseName
    }
    await updateEnterpriseName(payload, setLoading, setRefresh)
  }

  return (
    <Box bg="#fff" p="50px">
      <Stack spacing="30px" maxW="500px">
        <Stack direction="column">
          <Box>
            <Text {...formTitleStyle} mb="10px">
              Enterprise Name
            </Text>
            <Input
              onChange={(e) => setEnterpriseName(e.target.value)}
              type="text"
              value={enterpriseName}
              {...formInputStyle}
            />
          </Box>
          {/* <Box>
            <Text {...formTitleStyle}>Last Name</Text>
            <Input
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              value={lastName}
              {...formInputStyle}
            />
          </Box> */}
        </Stack>
        {/* <Stack direction="row">
          <Box>
            <Text {...formTitleStyle}>Date of Birth</Text>
            <Input
              onChange={(e) => setDob(e.target.value)}
              type="text"
              value={dob}
              {...formInputStyle}
            />
          </Box>
          
          <Box>
            <Text {...formTitleStyle}>Gender</Text>
            <Input
              onChange={(e) => setGender(e.target.value)}
              type="text"
              value={gender}
              {...formInputStyle}
            />
          </Box>
        </Stack> */}

        <Button
          _hover={{ bg: "#5C2BA8" }}
          bg="#5C2BA8"
          color="#fff"
          isLoading={loading}
          isDisabled={!enterpriseName}
          onClick={handleUpdateEnterpriseName}
        >
          Save Changes
        </Button>
      </Stack>
    </Box>
  );
}
