import {
  Box,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ArcElement, Title, Tooltip, Chart } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { GoPrimitiveDot } from "react-icons/go";
Chart?.register(Tooltip, Title, ArcElement);

export const DepartmentCardChart = ({ getData }) => {
  const { aboveAverageCount, belowAverageCount, count, testName } =
    getData || {};

  let arrayData = [aboveAverageCount, belowAverageCount];

  let theTotalValue = arrayData?.reduce(function (previousValue, currentValue) {
    return previousValue + currentValue;
  });

  const data = {
    // labels: ["Red", "Blue", "Green"],
    datasets: [
      {
        data: arrayData,
        backgroundColor: ["#47248E", "#EFDE6D"],
        cutout: 100,
      },
    ],
  };
  const plugins = [
    {
      beforeDraw: function (chart) {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        // const fontSize = (height / 7).toFixed(2);
        ctx.font = 50 + "px Gilroy-Medium";

        ctx.textBaseline = "top";
        var textX = Math.round((width - ctx.measureText(count).width) / 2),
          textY = height / 2.5;
        ctx.fillText(count || 0, textX, textY);

        ctx.restore();
        ctx.font = `15px Gilroy-Medium`;
        ctx.fillText("Total staff", textX - 20, textY + 60);
        ctx.save();
      },
    },
  ];

  return (
    <Box>
      <Stack
        my="20px"
        w="450px"
        maxW={"450px"}
        direction={"column"}
        bg="#FFFFFF"
        py="10px"
        px="30px"
        rounded={"20px"}
        align="center"
      >
        <Text fontWeight={"bold"}> {testName}</Text>
        <Stack direction={"row"} align="center" spacing={"-5"}>
          <Stack flex={"1"} maxH="240px">
            <Doughnut
              data={data}
              plugins={plugins}
              style={{
                marginTop: "-40px",
                transform: "scale(0.8)",
              }}
            />
          </Stack>
          <Stack flex={"1"} lineHeight={1.0} mt={"60px"}>
            <VStack alignItems={"flex-start"}>
              <Text fontWeight={"bold"} fontSize="1.2em">
                {theTotalValue ? (aboveAverageCount / theTotalValue) * 100 : 0}%
              </Text>
              <HStack>
                <GoPrimitiveDot color="#47248E" size="1.6rem" />
                <Text fontWeight="bold" fontSize={".7em"}>
                  Above Average
                </Text>
              </HStack>
            </VStack>
            <VStack alignItems={"flex-start"}>
              <Text fontWeight={"bold"} fontSize="1.2em">
                {theTotalValue ? (belowAverageCount / theTotalValue) * 100 : 0}%
              </Text>
              <HStack>
                <GoPrimitiveDot color="#EFDE6D" size="1.6rem" />
                <Text fontWeight="bold" fontSize={".7em"}>
                  Below Average
                </Text>
              </HStack>
            </VStack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
