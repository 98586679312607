import {
  Stack,
  Input,
  Box,
  Text,
  Button,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
// import { UpdateProfileHook } from "./UpdateProfileHook";
import { useEffect, useState } from "react";
import { updatePassword } from "../services/updatePassword";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const ChangePasswordForm = ({ data, setRefresh }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [disabled, setDisabled] = useState(true);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const user = useSelector(({ userData }) => userData?.currentUser);

  const history = useHistory();
  const formTitleStyle = {
    fontSize: ".9em",
  };
  const formInputStyle = {};

  // const { updatePasswordInfo } = UpdateProfileHook();

  const handleUpdate = async () => {
    setLoading(true);
    const payload = {
      oldPassword,
      newPassword,
      email: user?.email,
    };
    await updatePassword(payload, setLoading, setRefresh, history);
  };

  //check validation
  useEffect(() => {
    const isNotValid =
      newPassword !== confirmNewPassword || !newPassword || !confirmNewPassword;
    if (isNotValid) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [newPassword, confirmNewPassword]);

  return (
    <Box bg="#fff" p="50px">
      <Stack spacing="30px" maxW="500px">
        <Stack direction="row">
          <Box>
            <Text {...formTitleStyle}>Old Password</Text>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                onChange={(e) => setOldPassword(e.target.value)}
                value={oldPassword}
                type={showOldPassword ? "text" : "password"}
                {...formInputStyle}
              />

              <InputRightElement width="4.5rem">
                <Button
                  bg="transparent"
                  _hover={{ background: "transparent" }}
                  _focus={{ background: "transparent" }}
                  h="1.75rem"
                  size="sm"
                  onClick={() => setShowOldPassword((prev) => !prev)}
                >
                  {showOldPassword ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
          <Box>
            <Text {...formTitleStyle}>New Password</Text>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                type={showNewPassword ? "text" : "password"}
                {...formInputStyle}
              />

              <InputRightElement width="4.5rem">
                <Button
                  bg="transparent"
                  _hover={{ background: "transparent" }}
                  _focus={{ background: "transparent" }}
                  h="1.75rem"
                  size="sm"
                  onClick={() => setShowNewPassword((prev) => !prev)}
                >
                  {showNewPassword ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </Stack>
        <Stack direction="row">
          <Box>
            <Text {...formTitleStyle}>Confirm New Password</Text>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                value={confirmNewPassword}
                type={showConfirmNewPassword ? "text" : "password"}
                {...formInputStyle}
              />

              <InputRightElement width="4.5rem">
                <Button
                  bg="transparent"
                  _hover={{ background: "transparent" }}
                  _focus={{ background: "transparent" }}
                  h="1.75rem"
                  size="sm"
                  onClick={() => setShowConfirmNewPassword((prev) => !prev)}
                >
                  {showConfirmNewPassword ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </Stack>

        <Button
          _hover={{ bg: "#5C2BA8" }}
          bg="#5C2BA8"
          color="#fff"
          disabled={disabled}
          isLoading={loading}
          onClick={handleUpdate}
        >
          Save Changes
        </Button>
      </Stack>
    </Box>
  );
};
