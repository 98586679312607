import http, { AUTH_ROUTES } from "../../../apiConfig/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const deleteStaff = async (id, setLoading, setRefresh) => {
  try {
    const result = await http.delete(AUTH_ROUTES.DELETE_STAFF(id));
    successNotifier(result?.data?.deleteStaff?.message);
    setLoading(false);
    setRefresh({});
  } catch (error) {
    setLoading(false);
    setRefresh({});
    errorNotifier(error?.response?.data?.data);
    // throw new Error();
  }
};
