import http, { AUTH_ROUTES } from "../../../apiConfig/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const updateStaffDepartment = async (
  id,
  payload,
  setLoading,
  setRefresh
) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_STAFF_DEPARTMENT(id), payload);
    successNotifier("Staff Department successfully updated");
    setLoading(false);
    setRefresh({});
  } catch (error) {
    setLoading(false);
    errorNotifier(error?.response?.data?.data);
  }
};
