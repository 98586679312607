import "./dashboardLanding.scss";
import { Heading, Text, Flex, Image, Button, Stack } from "@chakra-ui/react";
import CurveBg from "../../assets/curve.svg";
import CurveBg2 from "../../assets/curve2.svg";
import bx2Icon from "../../assets/box2.svg";
import bx3Icon from "../../assets/box3.svg";
import { Box } from "@chakra-ui/react";
import { InviteStaffModal } from "../inviteStaff/components/InviteStaffModal";
import FullPageLoader from "../../components/fullPageLoader";
import { getAllTests } from "../test/services/getAllTests";
import { getStaff } from "../staffList/services/getStaff";
import { getDepartments } from "../staffList/services/getDepartment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import wallet from "../../assets/wallet-add.svg";
import { Link } from "react-router-dom";
import { getSubscriptions } from "../subscription/services";
import { getTopTest } from "./service";

const DashboardLanding = () => {
  const [allStaff, setAllStaff] = useState([]);
  const [allTests, setAllTests] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState({});

  const [currentSubscriptionPackage, setCurrentSubscriptionPackage] =
    useState("");

  const user = useSelector(({ userData }) => userData?.currentUser);
  const [topTest, setTopTest] = useState({ testName: "None", value: "0%" });
  const { enterpriseCode, enterpriseName } = user || {};

  useEffect(() => {
    getAllTests(setAllTests, setLoading);
    getStaff(enterpriseCode, setLoading, setAllStaff);
    getDepartments(enterpriseCode, setLoading, setDepartments);
  }, [refresh, enterpriseCode]);

  useEffect(() => {
    getSubscriptions()
      .then((data) => {
        setCurrentSubscriptionPackage(
          data?.subscriptions?.[0]?.subscriptionId?.subscriptionName
        );
      })
      .catch((e) => {
        setCurrentSubscriptionPackage("No");
      });

    getTopTest()
      .then((res) => setTopTest(res))
      .catch((e) => null);
  }, []);

  return loading ? (
    <FullPageLoader />
  ) : (
    <div style={{ paddingTop: "100px" }} className="dashboard-landing">
      <div className="top-bx">
        <div
          style={{
            backgroundImage: `url(${CurveBg})`,
            backgroundSize: "cover",
          }}
          className="box bx1"
        >
          <Heading fontFamily={"Gilroy-Bold"} color="#200960" mb="20px">
            {enterpriseName}
          </Heading>
          <div className="txts">
            <p className="label">Enterprise code:</p>
            <p className="val">{enterpriseCode}</p>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${CurveBg2})`,
            backgroundSize: "cover",
          }}
          className="box bx2"
        >
          <img src={bx2Icon} alt="illu" />
          <Heading
            fontFamily={"Gilroy-Bold"}
            mb="10px"
            as="h1"
            color="#403058"
            size="md"
          >
            Invite Staff
          </Heading>
          <Text color="#403058" maxW="250px" noOfLines={3}>
            Invite Staff or department in your organization to take a test or
            see a Therapist
          </Text>
          <br />
          <InviteStaffModal
            allTests={allTests}
            allStaff={allStaff}
            setRefresh={setRefresh}
            departments={departments}
            enterpriseCode={enterpriseCode}
          />
        </div>
      </div>
      <div className="btm-bx">
        <div className="box bxa">
          <img src={bx3Icon} alt="illu" />
          <Text
            color="#686278"
            style={{ fontWeight: "700" }}
            fontSize="lg"
            noOfLines={1}
          >
            Frequently taken tests
          </Text>

          <Box className="focus" mt="70px">
            <Text color="#403058" fontSize="xl">
              {topTest?.testName}:
            </Text>
            <Heading fontFamily={"Gilroy-Bold"} color="#403058">
              {topTest?.value}
            </Heading>
          </Box>

          <Link to="/dashboard/test-report">
            <div className="btn">
              <button>View More</button>
            </div>
          </Link>
        </div>
        <Flex
          bg="#F6F4F9"
          justifyContent={"space-between"}
          p="35px"
          className="box bxb"
        >
          <Box>
            <Text color="#686278" fontWeight={"semibold"}>
              Current Subscription
            </Text>
            <Stack flexDirection={"column"} alignItems="space-between">
              <Text mt="15px" color="#3D348B">
                {currentSubscriptionPackage} Package
              </Text>
              <Stack direction={"row"} alignItems={"center"} mt="20px">
                <Text
                  fontFamily={"Gilroy-Bold"}
                  fontSize={"40px"}
                  fontWeight="bold"
                  color="#3D348B"
                >
                  {user?.credits}
                </Text>
                <Flex height={"100%"} alignItems="end">
                  <Text color="#3D348B">Credits Remaining</Text>
                </Flex>
              </Stack>
              {/* <Text>21st March, 2022</Text>
                <Text color="#BBBBBB" mt="-20px" fontSize={"14px"}>
                  Expiring date
                </Text> */}
            </Stack>
          </Box>
          <Flex
            direction={"column"}
            justifyContent="space-between"
            alignItems={"flex-end"}
          >
            <Image width="60px" src={wallet} alt="wallet image" />{" "}
            <Link to="/dashboard/subscription">
              <Button
                marginTop="1rem"
                backgroundColor="#5C2BA8"
                color="#fff"
                padding=".6rem 1.8rem"
                borderRadius="20px"
                _hover={{ backgroundColor: "#5C2BA8" }}
                // onClick={history.push("dashboard/subscription")}
              >
                Renew Subscription
              </Button>
            </Link>
          </Flex>
        </Flex>
      </div>
    </div>
  );
};

export default DashboardLanding;
