import { Button, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { errorNotifier } from "../../../components/NotificationHandler";
import { sendOtpCode } from "../service";

function ForgotPasswordField({ email, setEmail, goNext }) {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!email) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [email]);

  const sendCode = () => {
    setLoading(true);
    let payload = {
      email,
    };
    sendOtpCode(payload)
      .then((e) => {
        setLoading(false);
        goNext(2);
      })
      .catch((e) => {
        setLoading(false);
        errorNotifier(e?.response?.data?.data);
      });
  };

  return (
    <form>
      <Stack maxW="300px" alignItems={"flex-start"}>
        <Text
          lineHeight={0.9}
          textAlign={"left"}
          fontSize="3.5em"
          fontFamily="Trenda-regular"
          color="#200960"
        >
          Forget <br />
          <span style={{ fontWeight: "bold", fontFamily: "Gilroy-Medium" }}>
            Password
          </span>
        </Text>
        <Text fontSize={".9em"} textAlign="start">
          Enter Your email address to get verification code
        </Text>
      </Stack>

      <Stack alignItems={"flex-start"} mt="30px">
        <Text>Email</Text>{" "}
        <input
          type="email"
          placeholder="Enter email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          isDisabled={disabled}
          isLoading={loading}
          size={"xl"}
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            sendCode();
          }}
        >
          {" "}
          Continue
        </Button>
      </Stack>
    </form>
  );
}

export default ForgotPasswordField;
