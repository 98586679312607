import { Stack, Text } from '@chakra-ui/react'
// import { CurveBackgroundColor } from '../../../components/CurveBackgroundColor'
import { SettingsAccordion } from './SettingsAccordion'

export const UserProfileSettings = ({ setRefresh, data }) => {
  return (
    <Stack spacing="30px">
      <Text>
        <b>Settings</b>
      </Text>
      <SettingsAccordion setRefresh={setRefresh} data={data} />
    </Stack>
  );
};
