import React, { useState } from "react";
import "./dashboard.scss";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Sidebar from "../../components/sidebar/sidebar";
import DashboardLanding from "../dashboardLanding/dashboardLanding";
import Header from "../../components/header/header";
import { Profile } from "../profile/index";
import { DoctorsComments } from "../doctorsComments";
import { StaffList } from "../staffList";
import { InviteStaff } from "../inviteStaff";
import { TestReport } from "../test";
import { TestReportDetailsScreen } from "../test/components/TestReportDetailsScreen";
import { Subscription } from "../subscription";
import { Notifications } from "../notifications";
// import ForgotPassword from "../forgotPassword";
// import { Profile } from "../profile/index"

// const config = {
//     headers: {
//        "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
//        }
// }

const Dashboard = () => {
  const { path } = useRouteMatch();

  const [navOpen] = useState(true);

  // const currentUser = useSelector(({ userData }) => userData.currentUser);

  return (
    <div className="dashboard">
      <div className="left">
        <Sidebar navOpen={navOpen} />
      </div>

      <div className="right" id={navOpen ? "reduce" : "full"}>
        <div className="header">
          <Header />
        </div>
        <Switch>
          <Route exact path={path} component={DashboardLanding} />
          <Route exact path={`${path}/subscription`} component={Subscription} />
          <Route
            exact
            path={`${path}/test-report/view-report/:name/:id/:isDepartment`}
            component={TestReportDetailsScreen}
          />
          <Route path={`${path}/test-report`} component={TestReport} />

          <Route exact path={`${path}/invite`} component={InviteStaff} />
          <Route exact path={`${path}/staff`} component={StaffList} />
          <Route exact path={`${path}/profile`} component={Profile} />
          <Route exact path={`${path}/comments`} component={DoctorsComments} />
          <Route
            exact
            path={`${path}/notifications`}
            component={Notifications}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Dashboard;
