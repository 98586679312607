import { userActionTypes } from "../constants/userActionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import { baseURL } from "../../apiConfig/api";

export const signup = (formData, history) => async (dispatch) => {
  try {
    const { data } = await axios.post(baseURL + "/auth/sign-up", formData);
    dispatch({ type: userActionTypes.SIGNUP_SUCCESS, data });

    history.push("/login");
  } catch (error) {}
};

export const signin = (formData, history) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${baseURL}/auth/customer/login`,
      formData
    );
    localStorage.setItem("HBET_TK", data.token);
    dispatch({ type: userActionTypes.SIGNIN_SUCCESS, payload: data });
    window.location.reload();
  } catch (error) {
    toast.error(error?.response?.data?.message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};

export const logout = (history) => async (dispatch) => {
  try {
    localStorage.removeItem("HBET_TK");
    await dispatch({ type: userActionTypes.LOGOUT_SUCCESS });
  } catch (error) {
    if (error.response) {
      return null;
    }
  }
};

export const loaduser = (history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.HBET_TK}`,
      },
    };
    if (localStorage.HBET_TK) {
      const { data } = await axios.get(
        `${baseURL}/enterprise/enterprise-detail?_id=${localStorage.HBET_TKID}`,
        config
      );
      dispatch({
        type: userActionTypes.LOADUSER_SUCCESS,
        payload: data.enterprise.data.enterprise[0],
      });
    } else {
      // throw Error('token is absent')
      localStorage.removeItem("HBET_TK");
      window.location.href = "/";
    }
  } catch (error) {
    localStorage.removeItem("HBET_TK");
    window.location.href = "/";
    if (error.response) {
      localStorage.removeItem("HBET_TK");
      window.location.href = "/";
    }
  }
};
