import { toast } from "react-toastify";
import http, { AUTH_ROUTES } from "../../../apiConfig/api";

export const verifyEmail = async (payload, history, setLoading) => {
  try {
    await http.post(AUTH_ROUTES.VERIFY_EMAIL, payload);
    setLoading(false);
    toast.success("Verification successful, please login");
    history.push("/login");
  } catch (e) {
    setLoading(false);
    toast.error(e.response?.data?.data);
    setLoading(false);
  }
};
