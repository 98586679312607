import { Flex, Select, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { BiDownload } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { useCSVDownloader } from "react-papaparse";

export const HeaderRow = ({
  match,
  setTimePeriod,
  timePeriod,
  title,
  analytics,
}) => {
  const { CSVDownloader } = useCSVDownloader();
  const history = useHistory();
  const goBack = () => {
    history.push("/dashboard/test-report");
  };

  const {
    dailyAudioSession,
    dailyChatSession,
    dailyVideoSession,
    totalLogins,
    totalSessionsBooked,
    totalSessionsUsed,
    totalStaff,
  } = analytics?.data || {};

  return (
    <Flex width={"100%"} justifyContent="space-between" alignItems={"center"}>
      <Flex
        align="center"
        mb="20px"
        onClick={goBack}
        cursor="pointer"
        color="#636161"
      >
        <BsArrowLeft size={26} style={{ margin: "0 15px 0px 0px" }} />
        <Text fontSize="18px">Back</Text>
      </Flex>
      <Flex alignItems={"center"}>
        <CSVDownloader
          filename={`${title} Report`}
          bom={true}
          config={{
            delimiter: ",",
          }}
          data={[
            {
              dailyAudioSession,
              dailyChatSession,
              dailyVideoSession,
              totalLogins,
              totalSessionsBooked,
              totalSessionsUsed,
              totalStaff,
            },
          ]}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            border="1px solid #09B5A9"
            color="#09B5A9"
            mr="50px"
            py="10px"
            px="20px"
            justifyContent={"center"}
            borderRadius="5px"
            cursor={"pointer"}
          >
            <BiDownload fontSize=".9em" />
            <Text fontSize=".8em"> CSV</Text>
          </Stack>
        </CSVDownloader>

        <Select
          bg="#fff"
          color="#636161"
          onChange={(e) => setTimePeriod(e.target.value)}
          defaultValue={timePeriod}
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </Select>
      </Flex>
    </Flex>
  );
};
