import http, { AUTH_ROUTES } from "../../../apiConfig/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const deleteDepartment = async (id, setLoading, setRefresh) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_DEPARTMENT(id));
    successNotifier("Departments successfully deleted");
    setLoading(false);
    setRefresh({});
  } catch (error) {
    setLoading(false);
    errorNotifier(error?.response?.data?.data);
    // throw new Error();
  }
};
