import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react'
import { StaffTableRow } from './StaffTableRow';

export const InviteStaffHistoryTab = ({ currentClickIndex,allTests, invitedStaff, enterpriseCode, setRefresh }) => {
  return (
    <Box>
      <Flex
        width={"100%"}
        // justifyContent="space-between"
        px="30px"
        mt="40px"
        mb="20px"
        color="#575757"
        fontWeight={"semibold"}
      >
        <Text width="19%">Name</Text>
        <Text width="20%" textAlign={"left"}>
          Test
        </Text>
        <Text width="21%">Date</Text>
        <Text width="21%">Status</Text>
        <Text width="19%" px="20px">
          Action
        </Text>
      </Flex>

      {invitedStaff?.length > 0 ? (
        <StaffTableRow
          allTests={allTests}
          setRefresh={setRefresh}
          invitedStaff={invitedStaff}
          enterpriseCode={enterpriseCode}
          currentClickIndex={currentClickIndex}
        />
      ) : (
        <Text textAlign={"center"} color="grey" marginTop="150px">
          No data available
        </Text>
      )}
    </Box>
  );
};
