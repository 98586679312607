import http, { AUTH_ROUTES } from "../../../apiConfig/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const updateDepartment = async (payload, setLoading, setRefresh) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_DEPARTMENT, payload);
    successNotifier("Department details successfully updated");
    setLoading(false);
    setRefresh({});
  } catch (error) {
    setLoading(false);
    errorNotifier(error?.response?.data?.data);
    // throw new Error();
  }
};
