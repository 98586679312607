import http, { AUTH_ROUTES } from "../../../apiConfig/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const validateCodeAndChangePassword = async (
  payload,
  setLoading,
  history
) => {
  try {
    await http.post(AUTH_ROUTES.VERIFY_CODE, payload);
    successNotifier("Successfully changed password");
    history.push("/");
    setLoading(false);
  } catch (error) {
    setLoading(false);
    errorNotifier(error?.response?.data?.data);
  }
};

export const sendOtpCode = async (payload) => {
  const result = await http.post(AUTH_ROUTES.INITIATE_FORGOT_PASSWORD, payload);
  return result;
};
