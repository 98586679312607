import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Select,
  Box,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { createStaff } from "../services/createStaff";
import { FormField } from "./FormField";

export const AddStaff = ({ departments, enterpriseCode, setRefresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAddStaff = async () => {
    const payload = {
      email,
      address,
      lastName,
      firstName,
      department,
      phoneNumber,
      enterpriseCode,
    };

    setLoading(true);
    await createStaff(payload, setLoading, setRefresh);
    onClose();
  };

  return (
    <>
      <Button
        bg="#5C2BA8"
        color="#fff"
        px="40px"
        borderRadius={"20px"}
        _hover={{ bg: "#5C2BA8" }}
        onClick={onOpen}
      >
        Add Staff
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Staff</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody mt="20px">
            <FormField
              type="text"
              labelTitle={"First Name"}
              value={firstName}
              setValue={setFirstName}
            />
            <Box mt="10px">
              <FormField
                type="text"
                labelTitle={"Last Name"}
                value={lastName}
                setValue={setLastName}
              />
            </Box>
            <Select
              placeholder="Choose department"
              my="25px"
              bg="#F3F3F3"
              color="#5C2BA8"
              onChange={(e) => setDepartment(e.target.value)}
            >
              {departments?.map((department) => (
                <option value={department?._id} key={department?._id}>
                  {department?.name}
                </option>
              ))}
            </Select>
            <Box>
              <FormField
                type="text"
                labelTitle={"Phone Number"}
                value={phoneNumber}
                setValue={setPhoneNumber}
              />
            </Box>
            <Box my="20px">
              <FormField
                type="email"
                labelTitle={"Email Address"}
                value={email}
                setValue={setEmail}
              />
            </Box>
            <Box my="20px">
              <FormField
                type="text"
                labelTitle={"Address"}
                value={address}
                setValue={setAddress}
              />
            </Box>
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent="center" mb="20px">
            <Button
              px="50px"
              borderRadius={"20px"}
              bg="#5C2BA8"
              color="#fff"
              _hover={{ bg: "#5C2BA8", color: "#fff" }}
              isLoading={loading}
              isDisabled={!email || !address || !firstName || !lastName || !department || !phoneNumber }
              onClick={handleAddStaff}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
