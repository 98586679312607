import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  // Select,
  // Box,
  Button,
  useDisclosure,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdEditNote } from "react-icons/md";
import { updateDepartment } from "../services/updateDepartment";
import { FormField } from "./FormField";

export const EditDepartmentModal = ({
  name,
  // hod,
  // members,
  _id,
  setRefresh,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const [headOfDept, setHeadOfDept] = useState(() => hod);
  // const [phoneNumber, setPhoneNumber] = useState(() => staffPhoneNumber);
  const [loading, setLoading] = useState(false);
  const [deptName, setDeptName] = useState(() => name);

  const handleUpdateDepartment = async () => {
    setLoading(true);
    const payload = {
      _id,
      name: deptName,
    };

    await updateDepartment(payload, setLoading, setRefresh);
    onClose();
  };

  return (
    <>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        width="60px"
        height={"25px"}
        px="5px"
        bg="#5C2BA8"
        fontSize={"11px"}
        borderRadius="5px"
        cursor={"pointer"}
        onClick={onOpen}
      >
        <MdEditNote size={16} />
        <Text ml="5px">Edit</Text>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Department</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody mt="20px">
            <FormField
              type="text"
              labelTitle={"Department Name"}
              value={deptName}
              setValue={setDeptName}
            />
            {/* <Select
              placeholder="Select head of department"
              my="25px"
              bg="#F3F3F3"
              color="#5C2BA8"
              _placeholder={{ color: "#c4c4c4" }}
              onChange={(e) => setHeadOfDept(e.target.value)}
            >
              {members?.map((member, i) => (
                <option key={i} value={member}>
                  {member?.name}
                </option>
              ))}
            </Select> */}
            
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent="center" mb="20px">
            <Button
              px="50px"
              borderRadius={"20px"}
              bg="#5C2BA8"
              color="#fff"
              _hover={{ bg: "#5C2BA8", color: "#fff" }}
              isLoading={loading}
              onClick={handleUpdateDepartment}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
