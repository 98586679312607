import React, { useState } from "react";
import { Box, Button, Flex, Text, Tooltip, Center } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { MdKeyboardArrowRight } from "react-icons/md";

export const HealthIssuesCard = ({ title, testId, isDepartment }) => {
  const [btnVisibility, setBtnVisibility] = useState(false);

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      alignItems={"space-between"}
      justifyContent="space-between"
      bg="#F3F3F3"
      borderRadius="10px"
      p="30px"
      width="100%"
      height={"220px"}
    >
      <Box>
        <Tooltip label={title}>
          <Text
            fontSize="22px"
            fontWeight="bold"
            color="#200960"
            // mb="10px"
            isTruncated
            maxWidth={"250px"}
          >
            {title}
          </Text>
        </Tooltip>
        <Text color="#929292">View Report</Text>
      </Box>

      <Flex
        // height={"150px"}
        direction={"column"}
        alignItems="space-between"
        justifyContent="space-between"
      >
        <Box position={"relative"}>
          {btnVisibility && (
            <Link
              to={`test-report/view-report/${title}/${testId}/${isDepartment}`}
              onMouseOut={() => setBtnVisibility(!btnVisibility)}
              style={{ position: "relative", top: "45%" }}
            >
              <Button
                borderRadius={"20px"}
                color={"#fff"}
                bg="#200960"
                _hover={{ bg: "#200960" }}
                mt="10px"
                rightIcon={<BsArrowRight color="#09B5A9" />}
              >
                View Report
              </Button>
            </Link>
          )}
          <Center w="40px" h="40px" bg="#FFF5E9" color="white">
            <MdKeyboardArrowRight
              color="#200960"
              size={"1.9em"}
              style={{ borderRadius: "50px" }}
              onMouseEnter={() => setBtnVisibility(!btnVisibility)}
            />
          </Center>
        </Box>
      </Flex>
    </Box>
  );
};
