import http, { AUTH_ROUTES } from "../../../apiConfig/api";
// import {
//   errorNotifier,
//   successNotifier,
// } from "../../../components/NotificationHandler";

export const getStaffAnalytics = async (
  testId,
  setLoading,
  setStaffAnalytics,
  timePeriod = "daily"
) => {
  try {
    const result = await http.get(
      AUTH_ROUTES.GET_STAFF_ANALYTICS({ timePeriod, testId })
    );
    setStaffAnalytics(result?.data?.getstaffAnalytics);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

export const getDepartmentAnalytics = async (
  testId,
  setLoading,
  setAnalytics,
  timePeriod = "daily"
) => {
  try {
    const result = await http.get(
      AUTH_ROUTES.GET_DEPARTMENT_ANALYTICS({ timePeriod, testId })
    );
    setAnalytics(result?.data?.getDepartmentAnalytics);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};
