import { Button, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { validateCodeAndChangePassword } from "../service";

function VerificationCodeField({ email, token }) {
  const [code, setCode] = useState(token ? token : "");

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const style = {
    alignItems: "flex-start",
    w: "100%",
  };

  //validation
  useEffect(() => {
    if (
      !code ||
      !newPassword ||
      !confirmNewPassword ||
      confirmNewPassword !== newPassword
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [code, newPassword, confirmNewPassword]);

  const verify = () => {
    setLoading(true);
    let payload = {
      password: newPassword,
      otp: code,
      email,
    };
    validateCodeAndChangePassword(payload, setLoading, history);
  };

  return (
    <form>
      <Stack maxW="300px" alignItems={"flex-start"}>
        <Text
          lineHeight={0.9}
          textAlign={"left"}
          fontSize="3.5em"
          fontFamily="Trenda-regular"
          color="#200960"
        >
          Verify
          <span style={{ fontWeight: "bold", fontFamily: "Gilroy-Medium" }}>
            Code
          </span>
        </Text>
        {/* <Text fontSize={".9em"} textAlign="start">
          Enter code sent 
        </Text> */}
      </Stack>

      <Stack mt="30px">
        <Stack {...style}>
          <Text>Input code</Text>{" "}
          <input
            type="text"
            placeholder="Enter code sent to your email"
            required
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </Stack>
        <Stack {...style}>
          <Text>New Password</Text>{" "}
          <div className="p-word" style={{ width: "100%" }}>
            <input
              type={showNewPassword ? "text" : "password"}
              placeholder="Enter new password"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {showNewPassword && (
              <i
                onClick={() => setShowNewPassword((prev) => !prev)}
                className="fas fa-eye"
              ></i>
            )}
            {!showNewPassword && (
              <i
                onClick={() => setShowNewPassword((prev) => !prev)}
                className="fas fa-eye-slash"
              ></i>
            )}
          </div>
        </Stack>
        <Stack {...style}>
          <Text>Confirm New Password</Text>{" "}
          <div className="p-word" style={{ width: "100%" }}>
            <input
              type={showConfirmNewPassword ? "text" : "password"}
              placeholder="Confirm new password"
              required
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            {showConfirmNewPassword && (
              <i
                onClick={() => setShowConfirmNewPassword((prev) => !prev)}
                className="fas fa-eye"
              ></i>
            )}
            {!showConfirmNewPassword && (
              <i
                onClick={() => setShowConfirmNewPassword((prev) => !prev)}
                className="fas fa-eye-slash"
              ></i>
            )}
          </div>
        </Stack>

        <Button
          size={"xl"}
          isLoading={loading}
          isDisabled={disabled}
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            verify();
          }}
        >
          {" "}
          Continue
        </Button>
      </Stack>
    </form>
  );
}

export default VerificationCodeField;
