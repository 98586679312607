import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import dayjs from "dayjs";
import { ReInviteModal } from "./ReInviteModal";

export const DepartmentCard = ({
  allTests,
  setRefresh,
  currentClickIndex,
  enterpriseCode,
  departmentData,
}) => {
  return (
    <Box>
      {departmentData?.map((department, i) => (
        <Flex
          key={i}
          width="100%"
          alignItems={"center"}
          bg="#fff"
          color="#575757"
          px="20px"
          py="20px"
          borderRadius={"10px"}
          mb="10px"
        >
          <Tooltip label={department?.departmentId?.name}>
            <Text width="17%" maxWidth="17%" isTruncated textAlign="left">
              {department?.departmentId?.name}
            </Text>
          </Tooltip>
          <Tooltip label={department?.testId?.testName}>
            <Text
              width="16%"
              maxWidth="17%"
              isTruncated
              textAlign="left"
              color="#403058"
            >
              {department?.testId?.testName}
            </Text>
          </Tooltip>
          <Tooltip label={department?.staff?.length}>
            <Text width="17%" maxWidth="17%" isTruncated textAlign="left">
              {department?.staff?.length}
            </Text>
          </Tooltip>
          <Tooltip label={dayjs(department?.createdAt).format("DD-MMM-YYYY")}>
            <Text width="17%" maxWidth="17%" isTruncated textAlign="left">
              {dayjs(department?.createdAt).format("DD-MMM-YYYY")}
            </Text>
          </Tooltip>
          <Tooltip label={department?.status}>
            <Text
              width="17%"
              maxWidth="17%"
              isTruncated
              textAlign="left"
              color={
                department?.status?.toLowerCase() === "pending"
                  ? "#F79256"
                  : "#1E7200"
              }
            >
              <Text
                as="span"
                color={
                  department?.status?.toLowerCase() === "pending"
                    ? "#F79256"
                    : "#1E7200"
                }
                bg={
                  department?.status?.toLowerCase() === "pending"
                    ? "#FBD1A24F"
                    : "#B3FED14F"
                }
                p="8px 15px"
                borderRadius={"7px"}
                textTransform="capitalize"
              >
                {department?.status}
              </Text>
            </Text>
          </Tooltip>
          <Tooltip label={"Retake Test"}>
            <ReInviteModal
              allTests={allTests}
              departments={department}
              setRefresh={setRefresh}
              enterpriseCode={enterpriseCode}
              currentClickIndex={currentClickIndex}
              testToTake={department?.testId?.testName}
            />
          </Tooltip>

          {/* <Box display={"flex"} width="33%">
            <AvatarGroup size="md" max={3}>
              {department?.members?.map((member, i) => (
                <Avatar key={i} name={member} src={""} alt={member} />
              ))}
            </AvatarGroup>
          </Box> */}
          {/* <Text width="33%" isTruncated textAlign="left">
              {department?.email}
            </Text> */}
          {/* </Tooltip> */}
          {/* <Box
            display="flex"
            justifyContent={"space-between"}
            width="33%"
            pr="18%"
            color="#fff"
          >
            <EditDepartmentModal
              departmentName={department?.department}
              members={department?.members}
              hod={department?.headOfDepartment}
            />
            <CautionAlertDialog
              icon={
                <Tooltip
                  label={`delete ${department?.department}`}
                  aria-label="A tooltip"
                >
                  <Flex
                    justifyContent={"center"}
                    alignItems={"center"}
                    width="60px"
                    height={"25px"}
                    px="5px"
                    border="1px solid #E6E6E6"
                    bg="inherit"
                    color="#707070"
                    borderRadius="5px"
                    cursor={"pointer"}
                  >
                    <AiOutlineDelete size={16} />
                    <Text fontSize={"11px"} ml="5px" mt="3px">
                      Delete
                    </Text>
                  </Flex>
                </Tooltip>
              }
              cautionTitle={`Are you sure you want to delete ${department?.department} department?`}
              mt="5px"
              small={true}
              // onContinue={() => handleDelete(departmentf?._id)}
            />
          </Box> */}
        </Flex>
      ))}
    </Box>
  );
};
