import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Select,
  Box,
  Button,
  useDisclosure,
  Text,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import { FormField } from "../../staffList/components/FormField";
import { inviteDepartments } from "../services/inviteDepartment";
import { inviteStaff } from "../services/inviteStaff";

export const InviteStaffModal = ({
  allTests,
  allStaff,
  setRefresh,
  departments,
  enterpriseCode,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [staffId, setStaffId] = useState("");
  const [testId, setTestId] = useState("");
  const [inviteTitle, setInviteTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const [currentClickIndex, setCurrentClickIndex] = useState(1);

  const handleInvite = async () => {
    setLoading(true);

    const payload = {
      testId,
      staffId,
      inviteTitle,
      enterpriseCode,
    };

    const deptPayload = {
      testId,
      inviteTitle,
      enterpriseCode,
      departmentId: staffId,
    };

    currentClickIndex === 1
      ? await inviteStaff(payload, setLoading, setRefresh)
      : await inviteDepartments(deptPayload, setLoading, setRefresh);
    onClose();
    setTestId("");
    setStaffId("");
    setInviteTitle("");
    setRefresh({});
  };

  return (
    <>
      <Button
        marginTop="1rem"
        backgroundColor="#5C2BA8"
        color="#fff"
        padding=".6rem 3rem"
        borderRadius="20px"
        _hover={{ backgroundColor: "#5C2BA8" }}
        onClick={onOpen}
      >
        Invite
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#2A2047" fontWeight={"semibold"}>
            Invite {currentClickIndex === 1 ? "Staff" : "Department"}
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody mt="20px">
            <Stack
              direction="row"
              w="250px"
              borderRadius={"33px"}
              bg="#EFEDF3"
              justifyContent={"space-between"}
            >
              <Box
                borderRadius={"10px"}
                px="20px"
                py="10px"
                width="50%"
                fontSize=".9em"
                cursor={"pointer"}
                bg={currentClickIndex === 1 ? "#fff" : "#EFEDF3"}
                color={currentClickIndex === 1 ? "#696969" : "#696969"}
                onClick={() => {
                  setCurrentClickIndex(1);
                  // getAllStaff();
                }}
              >
                <Text>Individual</Text>
              </Box>
              <Box
                borderRadius={"10px"}
                px="20px"
                py="10px"
                width="50%"
                fontSize=".9em"
                cursor={"pointer"}
                bg={currentClickIndex === 2 ? "#fff" : "#EFEDF3"}
                color={currentClickIndex === 2 ? "#696969" : "#696969"}
                onClick={() => {
                  setCurrentClickIndex(2);
                  // getDepartment();
                }}
              >
                <Text>Department</Text>
              </Box>
            </Stack>

            <Text color="#200960" mt="30px">
              Invite staff or department in your organisation to take a test or
              see a therapist
            </Text>
            <Select
              placeholder={
                currentClickIndex === 1 ? `Choose Staff` : `Choose Department`
              }
              my="25px"
              bg="#F3F3F3"
              color="#5C2BA8"
              onChange={(e) => setStaffId(e.target.value)}
            >
              {currentClickIndex === 1
                ? allStaff?.map((staff) => (
                    <option value={staff?._id} key={staff?._id}>
                      {staff?.firstName} {staff?.lastName}
                    </option>
                  ))
                : departments?.map((department, i) => (
                    <option value={department?._id} key={department?._id}>
                      {department?.name}
                    </option>
                  ))}
            </Select>

            <Select
              placeholder="Choose Test"
              my="25px"
              bg="#F3F3F3"
              color="#5C2BA8"
              onChange={(e) => setTestId(e.target.value)}
            >
              {allTests?.map((test, i) => (
                <option value={test?._id} key={test?._id}>
                  {test?.testName}
                </option>
              ))}
            </Select>

            <Box my="20px">
              <FormField
                type="text"
                labelTitle={"Invite Title"}
                value={inviteTitle}
                setValue={setInviteTitle}
              />
            </Box>
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent="center" mb="20px">
            <Button
              px="50px"
              borderRadius={"20px"}
              bg="#5C2BA8"
              color="#fff"
              _hover={{ bg: "#5C2BA8", color: "#fff" }}
              isLoading={loading}
              isDisabled={!inviteTitle || !testId || !staffId}
              onClick={handleInvite}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
