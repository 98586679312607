import http, { AUTH_ROUTES } from "../../../apiConfig/api";
import { errorNotifier } from "../../../components/NotificationHandler";

export const updatePassword = async (
  payload,
  setLoading,
  setRefresh,
  history
) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_PASSWORD, payload);

    setLoading(false);
    setRefresh({});
    localStorage.removeItem("HBET_TK");
    localStorage.removeItem("HBET_TKID");
    history.push("/login");
  } catch (error) {
    setLoading(false);
    errorNotifier(error?.response?.data?.data);
    // throw new Error();
  }
};
