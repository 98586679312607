import {
  Box,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ArcElement, Title, Tooltip, Chart } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { GoPrimitiveDot } from "react-icons/go";
Chart?.register(Tooltip, Title, ArcElement);

export const Chartcard = ({ title, dataToDisplay }) => {
  const { abvAverage, belowAverage, totalLogins } = dataToDisplay || {};

  let arrayData = [abvAverage, belowAverage];

  let calculateSum = arrayData?.reduce(function (previousValue, currentValue) {
    return previousValue + currentValue;
  });

  const data = {
    datasets: [
      {
        data: arrayData,
        backgroundColor: ["#47248E", "#EFDE6D", "#00B3B3"],
        cutout: 100,
      },
    ],
  };
  const plugins = [
    {
      beforeDraw: function (chart) {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 75).toFixed(2);
        ctx.font = fontSize + "em Gilroy-Medium";
        ctx.textBaseline = "top";
        var textX = Math.round(
            (width - ctx.measureText(totalLogins).width) / 2
          ),
          textY = height / 2.5;
        ctx.fillText(totalLogins || 0, textX, textY);
        ctx.restore();
        ctx.font = `12px Gilroy-Medium`;
        ctx.fillText(title, textX, textY + 65);
        ctx.save();
      },
    },
  ];

  return (
    <Box>
      <Heading
        fontFamily={"Gilroy-Bold"}
        color="#636161"
        fontSize={"25px"}
        fontWeight={"semibold"}
        py="30px"
      >
        {title}
      </Heading>
      <Stack
        direction={"row"}
        bg="#FFFFFF"
        py="30px"
        px="30px"
        rounded={"20px"}
        align="center"
      >
        <Stack w="35%">
          <Doughnut data={data} plugins={plugins} />
        </Stack>
        <Flex lineHeight={1.0} mt={"60px"}>
          <VStack>
            <Text fontSize={["30px", "30px", "60px", "80px"]}>
              {calculateSum
                ? Number((abvAverage / calculateSum) * 100)?.toFixed()
                : 0}
              %
            </Text>
            <HStack>
              <GoPrimitiveDot color="#47248E" size="1.6rem" />
              <Text fontSize={["9px", "10px", "12px"]}>Above Average</Text>
            </HStack>
          </VStack>
          <VStack>
            <Text mx="30px" fontSize={["30px", "30px", "60px", "80px"]}>
              {calculateSum
                ? Number((belowAverage / calculateSum) * 100)?.toFixed()
                : 0}
              %
            </Text>
            <HStack>
              <GoPrimitiveDot color="#EFDE6D" size="1.6rem" />
              <Text fontSize={["9px", "10px", "12px"]}>Below Average</Text>
            </HStack>
          </VStack>
        </Flex>
      </Stack>
    </Box>
  );
};
