import React from "react";
import { Flex, Tr, Td, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
// import dayjs from "dayjs";

export const TableBody = ({ getData }) => {
  return (
    <>
      {getData?.map?.((data, i) => {
        return (
          <Tr
            key={i}
            bg="#fff"
            color="#686278"
            borderBottom="10px solid #f6f4f9"
            borderRadius="5px"
            fontWeight="bold"
            fontSize={".1.2em"}
          >
            <Td>{dayjs(data?.createdAt).format("D MMM, YYYY")}</Td>
            <Td>{dayjs(data?.createdAt).format("HH:mm A")}</Td>

            <Td>{data?.credits}</Td>
            <Td>
              {new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(data?.amount)}
            </Td>
            <Td>
              <Flex>
                <Text>
                  ********
                  {data?.card || "0000"}
                </Text>
              </Flex>
            </Td>
          </Tr>
        );
      })}

      {/* );
      })} */}
    </>
  );
};
