import React, { useState } from "react";

import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import "./signup.scss";
import { Link } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { baseURL } from "../../apiConfig/api";

const Signup = (props) => {
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enterpriseName, setEnterpriseName] = useState("");

  const history = useHistory();

  const formData = {
    enterpriseName: enterpriseName,
    phoneNumber: number,
    email: email,
    password: password,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      setLoading(true);

      try {
        await axios.post(`${baseURL}/enterprise/create-enterprise`, formData);
        setLoading(false);

        toast.success(
          "Successfully Signed up, please check your mail for verification link"
        );
        history.push("/");
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.errors[0]?.msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  function Button() {
    if (
      password.length >= 8 &&
      confirmPassword.length &&
      password === confirmPassword
    ) {
      return (
        <>
          <button type="submit">{loading && <Spinner />}Sign up</button>
          <br />
          <br />
        </>
      );
    } else {
      return (
        <>
          <button disabled type="submit">
            {loading && <Spinner />}Sign up
          </button>
          <br />
          <br />
        </>
      );
    }
  }

  return (
    <div className="sign-up">
      <div className="container">
        <div className="left"></div>
        <div className="right">
          <form onSubmit={handleSubmit}>
            <h3>Sign up</h3>
            <input
              type="text"
              placeholder="Enterprise Name"
              required
              value={enterpriseName}
              onChange={(e) => setEnterpriseName(e.target.value)}
            />{" "}
            <br />
            <input
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />{" "}
            <br />
            <input
              type="tel"
              placeholder="Phone Number"
              required
              minLength={11}
              maxLength={11}
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />{" "}
            <br />
            <div className="p-word">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye"
                ></i>
              )}
              {!showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye-slash"
                ></i>
              )}
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Re-enter Password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye"
                ></i>
              )}
              {!showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye-slash"
                ></i>
              )}
              <br />
              <br />
              <p className="terms" style={{ color: "gray" }}>
                By Signing up you agree to our{" "}
                <Link
                  style={{ color: "#113E82", textDecoration: "none" }}
                  to="/terms"
                >
                  Terms of Use
                </Link>{" "}
              </p>
              <br />
              {password.length > 0 && (
                <PasswordChecklist
                  rules={["minLength", "number", "match"]}
                  minLength={8}
                  value={password}
                  className="validation"
                  iconSize={11}
                  valueAgain={confirmPassword}
                  messages={{
                    minLength: "8 characters minimum",
                    // specialChar: "Contains a special character",
                    number: "Contains a number",
                    // capital: "Contains a capital number",
                    match: "Passwords match",
                  }}
                />
              )}
            </div>
            <Button />
            {/* <p className="forgot">or</p>
            <div className="google">
              <img
                style={{ transform: "translateY(7px)", marginRight: "8px" }}
                src={Google}
                alt=""
              />
              <span>Sign in with google</span>
            </div> */}
          </form>
          <Link to="/" style={{ textDecoration: "none" }}>
            <p className="forgot">
              Have an account already?{" "}
              <span style={{ color: "#113E82" }} className="forgot">
                Sign in
              </span>
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
