import http, { AUTH_ROUTES } from "../../../apiConfig/api";

export const getAllTests = async (setAllTests, setLoading) => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_ALL_TESTS);
    setAllTests(data?.data?.testDetails.data.test);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};
