import http, { AUTH_ROUTES } from "../../../apiConfig/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const createDepartments = async (payload, setLoading, setRefresh) => {
  try {
    await http.post(AUTH_ROUTES.CREATE_DEPARTMENTS, payload);
    setLoading(false);

    successNotifier("Departments successfully created");
    setRefresh({});
  } catch (error) {
    setLoading(false);
    errorNotifier(error?.response?.data.errors[0].msg);
    // throw new Error();
  }
};
