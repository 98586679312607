import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ChakraProvider, Box, Flex } from "@chakra-ui/react";
import { UserInformationComponent } from "./components/UserInformationComponent";
import { CurveBackgroundColor } from "../../components/CurveBackgroundColor";
import { UserProfileSettings } from "./components/UserProfileSettings";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { loaduser } from "../../redux/actions/auth";

export const Profile = () => {
  const [refresh, setRefresh] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const userInformation = useSelector(({ userData }) => userData.currentUser);

  useEffect(() => {
    dispatch(loaduser(history));
  }, [refresh, history, dispatch]);

  return (
    <ChakraProvider>
      <Box padding={"100px 50px"}>
        <Flex direction={["column", "column"]} mt="10px">
          <Box w={["100%", "100%", "100%"]} mr="40px">
            <CurveBackgroundColor bg="#fff">
              <UserInformationComponent
                data={userInformation}
                setRefresh={setRefresh}
              />
            </CurveBackgroundColor>
            <CurveBackgroundColor my="30px" bg="#fff">
              <UserProfileSettings
                data={userInformation}
                setRefresh={setRefresh}
              />
            </CurveBackgroundColor>
          </Box>
        </Flex>
      </Box>
    </ChakraProvider>
  );
};
