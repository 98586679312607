import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react'
import { StaffCard } from './StaffCard';

export const StaffTab = ({ currentClickIndex, departments, enterpriseCode, staff, setRefresh }) => {
  return (
    <Box>
      <Flex
        width={"100%"}
        px="30px"
        mt="40px"
        mb="20px"
        color="#575757"
        fontWeight={"semibold"}
      >
        <Text width="19%">Name</Text>
        <Text width="20%" textAlign={"left"}>
          Department
        </Text>
        <Text width="21%">Phone Number</Text>
        <Text width="21%">Email Address</Text>
        <Text width="19%" px="20px">
          Action
        </Text>
      </Flex>

      {staff?.length > 0 ? (
        <StaffCard
          currentClickIndex={currentClickIndex}
          staff={staff}
          departments={departments}
          enterpriseCode={enterpriseCode}
          // searchResult={searchResult}
          setRefresh={setRefresh}
        />
      ) : (
        <Text textAlign={"center"} color="grey" marginTop="150px">
          No data available
        </Text>
      )}
    </Box>
  );
};
