import {
  Stack,
} from '@chakra-ui/react'
// import { UserInformationsTabs } from './UserInformationsTab'
// import { UserMoreInformationTab } from './UserMoreInfomationTab'
import { UserProfileSummary } from './UserProfileSummary';
// import { useSelector } from 'react-redux';

export const UserInformationComponent = ({ data, setRefresh }) => {
  // const TABS = ['Information', 'More info']

  // const data = {
  //   firstName: "Emmanuel",
  //   lastName: "Ochade",
  //   email: "eochade15@gmail.com",
  //   phoneNumber: "07035607059",
  //   DOB: "01-03-1970",
  //   gender: "Male",
  //   bloodGroup: "B+",
  //   genotype: "AA",
  //   allergies: ["Peanuts"],
  //   disabilities: [],
  //   image: ""
  // }

  return (
    <Stack spacing="50px">
      <UserProfileSummary
        setRefresh={setRefresh}
        data={data}
      />
      {/* <Tabs>
        <TabList>
          {TABS?.map((data, id) => {
            return (
              <Tab
                _selected={{
                  borderBottom: '5px solid #5C2BA8',
                }}
                _focus={{ boxShadow: 'none' }}
                key={id}
              >
                {data}
              </Tab>
            )
          })}
        </TabList>

        <TabPanels>
          <TabPanel>
            <UserInformationsTabs data={data} />
          </TabPanel>
          <TabPanel>
            <UserMoreInformationTab data={data} />
          </TabPanel>
        </TabPanels>
      </Tabs> */}
    </Stack>
  );
};
