import {
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";

export const FormField = ({ labelTitle, type, value, setValue, disabled }) => {
  return (
    <FormControl>
      <FormLabel color="#200960" htmlFor={labelTitle}>
        {labelTitle}
      </FormLabel>
      <Input
        bg="#F3F3F3"
        color="#5C2BA8"
        id={labelTitle}
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        isDisabled={disabled}
      />
    </FormControl>
  );
}
