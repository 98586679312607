export const AUTH_ROUTES = {
  //PROFILE PAGE
  UPDATE_PASSWORD: `/enterprise/reset-password`,
  UPDATE_IMAGE: (id) => `/enterprise/image-upload?id=${id}`,
  UPDATE_PHONE: `/enterprise/update-phone`,
  UPDATE_EMAIL: `/enterprise/email-update`,
  UPDATE_ENTERPRISE_NAME: `/enterprise/update-name`,
  //INVITE PAGE
  INVITE_STAFF: `/enterprise/invite-staff`,
  INVITE_DEPARTMENT: `/enterprise/invite-department`,
  GET_INVITED_DEPARTMENTS: (code) =>
    `/enterprise/department-invites?enterpriseCode=${code}&sort=desc`,
  GET_INVITED_STAFF: (code) =>
    `/enterprise/staff-invites?enterpriseCode=${code}&sort=desc`,
  //STAFF LIST PAGE
  GET_STAFF: (code) => `/enterprise/staffs?enterpriseCode=${code}&sort=desc`,
  CREATE_STAFF: `/enterprise/staff/create-staff`,
  DELETE_STAFF: (id) => `/enterprise/delete-staff/${id}`,
  UPDATE_STAFF_DEPARTMENT: (id) => `/enterprise/staff/update-department/${id}`,
  GET_DEPARTMENTS: (code) =>
    `/enterprise/get-department?enterpriseCode=${code}&sort=desc`,
  CREATE_DEPARTMENTS: `/enterprise/create-department`,
  UPDATE_DEPARTMENT: `/enterprise/update-department`,
  DELETE_DEPARTMENT: (id) => `/enterprise/delete-department/${id}`,
  //TEST/ TEST REPORT
  GET_STAFF_ANALYTICS: ({ timePeriod, testId }) =>
    `/enterprise/staff/analytics?testId=${testId}&timePeriod=${timePeriod}`,
  GET_DEPARTMENT_ANALYTICS: ({ timePeriod, testId }) =>
    `/enterprise/department/analytics?departmentId=${testId}&timePeriod=${timePeriod}`,
  GET_ALL_TESTS: "/users/get-test?sort=asc",
  GET_DOCTORS_COMMENTS: "/enterprise/doctor-comments",
  GET_CREDIT_PURCHASED: (month, year) =>
    `/analytic/credit-purchased?month=${month}&year=${year}`,

  GET_CREDIT_USED: (month, year) =>
    `/analytic/credit-used?month=${month}&year=${year}`,
  GET_SUBSCRIPTION_LIST: "/enterprise/subscription-list",
  VERIFY_SUBSCRIPTIONS: "/subscriptions/verify",
  GET_SUBSCRIPTIONS: ({ limit, skip }) =>
    `/subscriptions?sort=desc&limit=${limit}&skip=${skip}&status=CONFIRMED`,
  CREATE_SUBSCRIPTIONS: "/subscriptions",
  GET_TOP_TEST: "/enterprise/top-test/analytics",
  GET_NOTIFICATIONS: (enterpriseId) =>
    `/notifications?recipientId=${enterpriseId}&sort=desc`,
  VERIFY_CODE: "/enterprise/verify-otp",
  INITIATE_FORGOT_PASSWORD: "/enterprise/initiate-forgot-password",
  RESEND_TOKEN: "/resend-token",
  VERIFY_EMAIL: "/enterprise/verify-email",
};
