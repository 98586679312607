import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Select,
  Box,
  Button,
  useDisclosure,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdEditNote } from "react-icons/md";
import { updateStaffDepartment } from "../services/updateStaffDepartment";
import { FormField } from "./FormField";

export const EditStaffModal = ({
  departments,
  staffDepartment,
  firstName,
  lastName,
  id,
  staffPhoneNumber,
  staffEmail,
  setRefresh,
  enterpriseCode,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [staffFirstName, setStaffFirstName] = useState(() => firstName);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(() => staffPhoneNumber);
  const [email, setEmail] = useState(() => staffEmail);
  const [departmentId, setDepartmentId] = useState(() => staffDepartment);

  const handleUpdateStaffDept = async () => {
    setLoading(true)
    const payload = {
      department: departmentId,
      enterpriseCode,
    };
    await updateStaffDepartment(id, payload, setLoading, setRefresh)
    onClose()
  }

  return (
    <>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        width="60px"
        height={"25px"}
        px="5px"
        bg="#5C2BA8"
        fontSize={"11px"}
        borderRadius="5px"
        cursor={"pointer"}
        onClick={onOpen}
      >
        <MdEditNote size={16} />
        <Text ml="5px">Edit</Text>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Staff</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody mt="20px">
            <FormField
              type="text"
              labelTitle={"Staff Name"}
              value={`${staffFirstName} ${lastName}`}
              setValue={setStaffFirstName}
              disabled
            />
            <Select
              placeholder="Choose department"
              my="25px"
              bg="#F3F3F3"
              color="#5C2BA8"
              value={departmentId}
              onChange={(e) => setDepartmentId(e.target.value)}
            >
              {departments?.map((department) => (
                <option value={department?._id} key={department?._id}>
                  {department?.name}
                </option>
              ))}
            </Select>
            <Box>
              <FormField
                type="text"
                labelTitle={"Phone Number"}
                value={phoneNumber}
                setValue={setPhoneNumber}
                disabled
              />
            </Box>
            <Box my="20px">
              <FormField
                type="email"
                labelTitle={"Email Address"}
                value={email}
                setValue={setEmail}
                disabled
              />
            </Box>
          </ModalBody>

          <ModalFooter display={"flex"} justifyContent="center" mb="20px">
            <Button
              px="50px"
              borderRadius={"20px"}
              bg="#5C2BA8"
              color="#fff"
              _hover={{ bg: "#5C2BA8", color: "#fff" }}
              isLoading={loading}
              onClick={handleUpdateStaffDept}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
