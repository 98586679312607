import { Box, ChakraProvider, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CurveBackgroundColor } from "../../components/CurveBackgroundColor";
import FullPageLoader from "../../components/fullPageLoader";
import { AddDepartmentModal } from "./components/AddDepartmentModal";
import { AddStaff } from "./components/AddStaffModal";
import { DepartmentTabTab } from "./components/DepartmentTab";
import { StaffTab } from "./components/StaffTab";
import { getDepartments } from "./services/getDepartment";
import { getStaff } from "./services/getStaff";

export const StaffList = () => {
  const [departments, setDepartments] = useState([]);
  const [allStaff, setAllStaff] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState({});
  const [currentClickIndex, setCurrentClickIndex] = useState(1);

  const user = useSelector(({ userData }) => userData?.currentUser);
  const code = user?.enterpriseCode;

  useEffect(() => {
    getStaff(code, setLoading, setAllStaff);
    getDepartments(code, setLoading, setDepartments);
  }, [code, refresh]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <ChakraProvider>
      <Box padding={"100px 50px"} bg="#EFEDF3" opacity={1}>
        <CurveBackgroundColor bg="#F6F4F9" p="30px">
          <Stack
            my="20px"
            // spacing="20px"
            justifyContent={"space-between"}
            direction={["column-reverse", "row"]}
            alignItems="center"
          >
            <Stack
              direction="row"
              w="250px"
              borderRadius={"33px"}
              bg="#EFEDF3"
              justifyContent={"space-between"}
            >
              <Box
                borderRadius={"10px"}
                px="20px"
                py="10px"
                width="50%"
                fontSize=".9em"
                cursor={"pointer"}
                bg={currentClickIndex === 1 ? "#fff" : "#EFEDF3"}
                color={currentClickIndex === 1 ? "#696969" : "#696969"}
                onClick={() => {
                  setCurrentClickIndex(1);
                  // getAllStaff();
                }}
              >
                <Text>Staff List</Text>
              </Box>
              <Box
                borderRadius={"10px"}
                px="20px"
                py="10px"
                width="50%"
                fontSize=".9em"
                cursor={"pointer"}
                bg={currentClickIndex === 2 ? "#fff" : "#EFEDF3"}
                color={currentClickIndex === 2 ? "#696969" : "#696969"}
                onClick={() => {
                  setCurrentClickIndex(2);
                  // getDepartment();
                }}
              >
                <Text>Department</Text>
              </Box>
            </Stack>
            {currentClickIndex === 1 ? (
              <AddStaff
                enterpriseCode={code}
                departments={departments}
                setRefresh={setRefresh}
              />
            ) : (
              <AddDepartmentModal
                enterpriseCode={code}
                setRefresh={setRefresh}
              />
            )}
            {/* <SearchBar searchDoctor={setSearch} placeholder="Search staff" /> */}
          </Stack>
          {currentClickIndex === 1 ? (
            <StaffTab
              staff={allStaff}
              departments={departments}
              currentClickIndex={currentClickIndex}
              enterpriseCode={code}
              setRefresh={setRefresh}
            />
          ) : (
            <DepartmentTabTab
              departments={departments}
              currentClickIndex={currentClickIndex}
              enterpriseCode={code}
              setRefresh={setRefresh}
            />
          )}
        </CurveBackgroundColor>
      </Box>
    </ChakraProvider>
  );
};
