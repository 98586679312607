import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

import cardBg from "../images/cardBg.svg";
import { greet } from "../../../utils/greet";

export const UserCard = ({ user, enterpriseCode, enterpriseName }) => {
  return (
    <Box
      backgroundImage={cardBg}
      backgroundSize="cover"
      borderRadius="10px"
      p="20px 40px"
      boxShadow="base"
    >
      <Flex justifyContent="space-between" direction={"column"} mb="20px">
        <Box mt="40px">
          <Text
            fontFamily={"Gilroy-Bold"}
            fontSize="26px"
            color="#200960"
            fontWeight="bold"
          >
            {enterpriseName}
          </Text>
          <Text fontSize="20pxpx" color="#200960" fontWeight="normal">
            {greet()}
          </Text>
          <Text mt="30px" fontSize={"18px"} color="#2A2047">
            Enterprise Code: {enterpriseCode}
          </Text>

          {/* <Link to="take-test">
            <Button
              _hover={{ bg: "#5C2BA8" }}
              bg="#5C2BA8"
              color="#fff"
              mt="20px"
              borderRadius={"25px"}
            >
              Take a test
            </Button>
          </Link> */}
        </Box>
      </Flex>
    </Box>
  );
};
