export const getComponentTitle = (pathname) => {
  const urls = window.location.href;
  const data = new URL(urls)?.pathname?.split("/")?.[3];
  switch (pathname) {
    case `/dashboard/test-report/view-report/${data}`:
      return `Test Report: ${data}`;

    case "/dashboard":
      return "Dashboard";
    case "/dashboard/subscription":
      return "Subscription";
    case "/dashboard/test-report":
      return "Test Report";
    case "/dashboard/comments":
      return "Therapists Comments";
    case "/dashboard/invite":
      return "Invite Staff";
    case "/dashboard/staff":
      return "Staff List";
    case "/dashboard/profile":
      return "Profile";
    case "/dashboard/notifications":
      return "Notifications";
    default:
      return "Welcome";
  }
};
