import http, { AUTH_ROUTES } from "../../../apiConfig/api";

export const getStaff = async (code, setLoading, setAllStaff) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_STAFF(code));
    setAllStaff(result?.data?.result?.data?.staff);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};
