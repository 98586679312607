import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { DepartmentCard } from "./Department";

export const DepartmentTabTab = ({
  invitedDepartment,
  currentClickIndex,
  allTests,
  enterpriseCode,
  setRefresh,
}) => {
  return (
    <Box>
      <Flex
        width={"100%"}
        // justifyContent="space-between"
        px="20px"
        mt="40px"
        mb="20px"
        color="#575757"
        fontWeight={"semibold"}
      >
        <Text width="21%" textAlign={"left"}>
          Dept Name
        </Text>
        <Text width="18%">Test</Text>
        <Text width="18%">No. of Staff</Text>
        <Text width="18%">Date</Text>
        <Text width="18%">Status</Text>
        <Text width="18%">Action</Text>
      </Flex>

      {invitedDepartment?.length > 0 ? (
        <DepartmentCard
          allTests={allTests}
          enterpriseCode={enterpriseCode}
          departmentData={invitedDepartment}
          currentClickIndex={currentClickIndex}
          setRefresh={setRefresh}
          // searchResult={searchResult}
          // refresh={() => setRefresh({})}
        />
      ) : (
        <Text textAlign={"center"} color="grey" marginTop="150px">
          No data available
        </Text>
      )}
    </Box>
  );
};
