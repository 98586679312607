// import {
//   errorNotifier,
//   successNotifier,
// } from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../apiConfig/api";

export const getDoctorsComments = async (setLoading, setDoctorsComments) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_DOCTORS_COMMENTS);
    setDoctorsComments(result?.data?.doctorComments?.data?.PatientNotes);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};
