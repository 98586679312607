import { Box, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { useDispatch } from 'react-redux';
import { changeSwitchIndex } from '../redux/actions/uiActions';

export const SwitchButtons = ({
  titleOne,
  titleTwo,
  currentClickIndex,
  setCurrentClickIndex,
}) => {


  const dispatch = useDispatch()

  return (
    <Stack
      direction="row"
      w="250px"
      borderRadius={"33px"}
      bg="#BBBBBB"
      justifyContent={"space-between"}
    >
      <Box
        borderRadius={"10px"}
        px="20px"
        py="10px"
        width="50%"
        fontSize=".9em"
        cursor={"pointer"}
        bg={currentClickIndex === 1 ? "#fff" : "#BBBBBB"}
        color={currentClickIndex === 1 ? "#696969" : "#696969"}
        onClick={() => {
          setCurrentClickIndex(1);
          // updateButtonOne();
          dispatch(changeSwitchIndex(currentClickIndex));
        }}
      >
        <Text>{titleOne}</Text>
      </Box>
      <Box
        borderRadius={"10px"}
        px="20px"
        py="10px"
        width="50%"
        fontSize=".9em"
        cursor={"pointer"}
        bg={currentClickIndex === 2 ? "#fff" : "#BBBBBB"}
        color={currentClickIndex === 2 ? "#696969" : "#696969"}
        onClick={() => {
          setCurrentClickIndex(2);
          // updateButtonTwo();
          dispatch(changeSwitchIndex(currentClickIndex));
        }}
      >
        <Text>{titleTwo}</Text>
      </Box>
    </Stack>
  );
};
