import React, { useState } from "react";
import "./login.scss";
// import { signin } from '../../redux/actions/auth';
import { Redirect, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as routes from "../../routing/route";
import { connect } from "react-redux";
import ButtonLoader from "../../components/buttonLoader/buttonLoader";
import { userActionTypes } from "../../redux/constants/userActionTypes";
import axios from "axios";
import { toast } from "react-toastify";

import ResendToken from "./components/ResendToken";
import { baseURL } from "../../apiConfig/api";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  // const history = useHistory();
  const dispatch = useDispatch();

  const [hasNotBeenVerified, setHasNotBeenVerified] = useState({
    bool: false,
    data: {},
  });

  const { isAuthenticated, currentUser } = props;
  const formData = {
    email,
    password,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${baseURL}/enterprise/login-enterprise`,
        formData
      );
      setLoading(false);

      localStorage.setItem("HBET_TK", data?.data?.token);
      localStorage.setItem("HBET_TKID", data?.data?._id);
      dispatch({ type: userActionTypes.SIGNIN_SUCCESS, payload: data?.data });
      // window.location.reload();
    } catch (error) {
      if (error?.response?.data?.login?.data?.emailVerification === false) {
        toast.error(error?.response?.data?.login?.message);
        setHasNotBeenVerified({
          bool: true,
          data: error?.response?.data?.login?.data,
        });
      } else {
        toast.error(error?.response?.data?.data);
      }

      setLoading(false);
    }
  };

  if (isAuthenticated && currentUser) {
    return <Redirect to={routes.DashBoard} />;
  }

  // const googleResponse = async (response) => {};

  return hasNotBeenVerified?.bool ? (
    <ResendToken id={hasNotBeenVerified?.data?._id} />
  ) : (
    <div className="login">
      <div className="container">
        <div className="left"></div>
        <div className="right">
          <form onSubmit={handleSubmit}>
            {/* <img id="logo" src={Logo} alt="wehaulogo" /> */}
            <h1>Sign in</h1>
            <input
              type="email"
              placeholder="Email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />{" "}
            <br />
            <div className="p-word">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye"
                ></i>
              )}
              {!showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye-slash"
                ></i>
              )}
            </div>
            <Link to="/signup" style={{ textDecoration: "none" }}>
              <p className="forgot">
                Don't have an account?{" "}
                <span style={{ color: "#113E82" }} className="forgot">
                  Sign up
                </span>
              </p>
            </Link>
            <button type="submit">{loading && <ButtonLoader />} Sign in</button>
            <Link
              to="/forgot-password/reset"
              style={{ textDecoration: "none" }}
            >
              <p className="forgot">Forgot password? click here</p>
            </Link>
            {/* <div className="google"><img style={{transform: 'translateY(7px)', marginRight: '8px'}} src={Google} alt="" /><span>Sign in with google</span></div> */}
            {/* <GoogleLogin
              clientId="570006213811-5vlu8tgn6n9hcd6m12a3le9o07ogk1kq.apps.googleusercontent.com"
              render={(renderProps) => (
                // <button className="google"  disabled={renderProps.disabled}>This is my custom Google button</button>
                <div onClick={renderProps.onClick} className="google">
                  <img
                    style={{ transform: "translateY(7px)", marginRight: "8px" }}
                    src={Google}
                    alt=""
                  />
                  <span>Sign in with google</span>
                </div>
              )}
              buttonText="Login"
              onSuccess={googleResponse}
              onFailure={googleResponse}
              cookiePolicy={"single_host_origin"}
              prompt="consent"
            /> */}
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ userData }) => ({
  isAuthenticated: userData.isAuthenticated,
  currentUser: userData.currentUser,
});

export default connect(mapStateToProps, null)(Login);
