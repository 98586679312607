import React, { useState, useEffect } from "react";
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { HealthIssuesCard } from "./components/HealthIssuesCard";
// import { CurveBackgroundColor } from "../../components/CurveBackgroundColor";
import { SwitchButtons } from "../../components/SwitchButtons";
import { useDispatch, useSelector } from "react-redux";
import { changeSwitchIndex } from "../../redux/actions/uiActions";
import FullPageLoader from "../../components/fullPageLoader";
import { getAllTests } from "./services/getAllTests";
import { getDepartments } from "../staffList/services/getDepartment";

export const TestReport = () => {
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [allTests, setAllTests] = useState([]);
  const [currentClickIndex, setCurrentClickIndex] = useState(1);
  const dispatch = useDispatch();

  const user = useSelector(({ userData }) => userData?.currentUser);
  const code = user?.enterpriseCode;

  useEffect(() => {
    dispatch(changeSwitchIndex(currentClickIndex));
    getAllTests(setAllTests, setLoading);
    getDepartments(code, setLoading, setDepartments);
  }, [dispatch, currentClickIndex, code]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box padding={"100px 50px"} bg="#EFEDF3" opacity={1}>
      {/* <CurveBackgroundColor bg="#F6F4F9" p="30px"> */}
      <Flex direction={["column", "column", "row"]}>
        <Box w={"100%"} mb={"50px"}>
          <SwitchButtons
            titleOne={"Test"}
            titleTwo={"Department"}
            updateButtonTwo={null}
            updateButtonOne={null}
            currentClickIndex={currentClickIndex}
            setCurrentClickIndex={setCurrentClickIndex}
          />
        </Box>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
        {currentClickIndex === 1
          ? allTests?.map((data, index) => {
              return (
                <Box w={{ base: "100%" }} key={index}>
                  <HealthIssuesCard
                    isDepartment={false}
                    testId={data?._id}
                    title={data?.testName}
                    description={data?.description}
                  />
                </Box>
              );
            })
          : departments?.map((data, index) => {
              return (
                <Box w={{ base: "100%" }} key={index}>
                  <HealthIssuesCard
                    isDepartment={true}
                    testId={data?._id}
                    title={data?.name}
                    description={data?.description}
                  />
                </Box>
              );
            })}
      </SimpleGrid>
      {/* </CurveBackgroundColor> */}
    </Box>
  );
};
